import React, { useState, useEffect } from "react";
import Header from "./Header";
import "./Css/DetailedLogoIdea.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader.js";
import { fabric } from "fabric";
import axios from "axios";
import {
  server_post_data,
  get_all_industry_data_details,
  get_admin_template_by_industry,
  save_update_user_templates_dublicate,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  handleIaphabetnumberChange,
  handleLinkClick,
} from "../CommonJquery/CommonJquery";
import { storeData, retrieveData } from "../LocalConnection/LocalConnection.js";
const DetailedLogoIdea = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [VacancytData, setVacancytData] = useState([]);
  const [cards, setcards] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  useEffect(() => {
    handleFetchData();
  }, []);

  // const createCanvas = (logoData, background) => {
  //   const originalWidth = 400;
  //   const originalHeight = 400;
  //   const scaleFactor = 110 / Math.max(originalWidth, originalHeight);
  //   console.log(logoData.objects);

  //   // Create a new canvas element
  //   const canvasElement = document.createElement("canvas");
  //   canvasElement.width = 110;
  //   canvasElement.height = 110;

  //   // Optionally, append the canvas to the DOM if you want to see it
  //   // document.body.appendChild(canvasElement);

  //   // Initialize Fabric.js canvas with the canvas element
  //   const canvas_temp = new fabric.Canvas(canvasElement);

  //   // Set the background color using Fabric.js API
  //   canvas_temp.setBackgroundColor(
  //     background || background,
  //     canvas_temp.renderAll.bind(canvas_temp)
  //   );

  //   logoData.objects.forEach((objData) => {
  //     objData.scaleX = (objData.scaleX || 1) * scaleFactor;
  //     objData.scaleY = (objData.scaleY || 1) * scaleFactor;
  //     objData.left = (objData.left || 0) * scaleFactor;
  //     objData.top = (objData.top || 0) * scaleFactor;

  //     fabric.util.enlivenObjects([objData], (objects) => {
  //       objects.forEach((obj) => {
  //         canvas_temp.add(obj);
  //       });
  //       canvas_temp.requestRenderAll();
  //     });
  //   });

  //   return canvas_temp.toDataURL();
  // };

  const maxLength = 20;

  const handleDuplicateOpenInEditor = async (primary_id) => {
    let fd_from = new FormData();
    const user_id = retrieveData("user_id");
    if (user_id) {
      fd_from.append("customer_id", user_id);
      fd_from.append("main_id", "1");
      fd_from.append("primary_id", primary_id);
      //console.log('this one');
      //setshowLoaderAdmin(true);
      try {
        const Response = await axios.post(
          save_update_user_templates_dublicate,
          fd_from,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Set the Content-Type header for form data
            },
          }
        );
        if (Response.status === 200) {
          //await server_post_data(save_update_user_templates, fd_from).then((Response) => {
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            //handleSuccess(Response.data.message);
            navigate(`/canvasEdit/logo/${Response.data.last_inserted_id}`);
          }
        }
      } catch (error) {
        handleError("network");
      }
    } else {
      if (!user_id) {
        var event = new CustomEvent("customEvent");
        document.getElementById("login_check_jquery").dispatchEvent(event);
      }
    }
  };

  const handleFetchData = async () => {
    setshowLoaderAdmin(true);
    var form_data = new FormData();
    form_data.append("main_id", id);
    await server_post_data(get_all_industry_data_details, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data.length > 0) {
            setVacancytData(Response.data.message.data[0]);

            handleIndustryFetchData(
              Response.data.message.data[0].industry_name
            );
          }
          setcards(Response.data.message.data_blog2);
          console.log(Response.data.message.data_blog2);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  console.log(VacancytData.industry_name);

  const handleIndustryFetchData = async (industryName) => {
    setshowLoaderAdmin(true);
    var form_data = new FormData();

    // Use the industryName parameter
    form_data.append("template_industry", industryName);

    await server_post_data(get_admin_template_by_industry, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleContinueClick = () => {
    if (!companyName) {
      setErrorMessage("Please fill in the Business Name");
    } else {
      setErrorMessage("");
      storeData("companyName", companyName);
      storeData("slogan", "");
      handleLinkClick("/createlogohome");
    }
  };
  const handleInputChange = (e) => {
    setCompanyName(e.target.value);
    storeData("companyName", e.target.value);
  };
  return (
    <>
      <Header />
      {showLoaderAdmin && <Loader />}
      <section className="detailed_logoidea_section">
        <div className="detailed_logoIdea_wrapper">
          <div className="container">
            <div className="HeaderSection_detailedLogoIdea">
              <div className="routeSection">
                <Link to="/">
                    <p>Logoacademy</p>
                </Link>
                <p>&ensp;/&ensp;</p>
                <Link to="/logoideas">
                    <p> LogoIdeas</p>
                </Link>
                <p>&ensp;/&ensp;</p>
                <span className="Dyanamic_nameSection_logoideas_detailed flexRow">
                  <p>{VacancytData.industry_name} </p> {"\t"}
                  <p> Logos </p>
                </span>
              </div>
              <div>
                <span className="heading_logoIdeas_detailed">
                  <h1>{VacancytData.industry_name} </h1>&ensp;
                  <h1>LogoIdeas</h1>
                </span>
              </div>
            </div>
            <div className="InputSection_detailedIdeas flexRow row">
              <div className="col-lg-4 col-md-12 col-12">
                <input
                  placeholder="Enter company name "
                  name="BusinessName"
                  maxLength={75}
                  onInput={handleIaphabetnumberChange}
                  onChange={(e) => handleInputChange(e)}
                />
                {errorMessage && (
                  <span className="error_message">{errorMessage}</span>
                )}
              </div>
              <div className="col-lg-3 col-12">
                <button onClick={() => handleContinueClick()}>
                  Create your {VacancytData.industry_name}
                </button>
              </div>
            </div>
            <div className="detailed_logoIdeas_description">
              {VacancytData.industry_name}
              <p className="color_text_detailedLogo">Logo maker.</p>
            </div>
          </div>
        </div>
        <div className="related_templates container">
          <div className="related_templates_header">
            <div className="flexRow">
              <h3>Professional {VacancytData.industry_name} logo designs: </h3>
            </div>
          </div>
          <div className="row px-sm-0 px-2">
            {cards.map((item, index) => (
              <div
                key={index}
                className="gallery-item col-lg-3 col-md-3 col-sm-4 col-6 p-1"
                onClick={() => {
                  handleDuplicateOpenInEditor(item.primary_id);
                }}
              >
                <div className="imageCOntIdea">
                  <img
                    src={`https://api.logoacademy.co/assets/${item.template_url}`}
                    alt={item.template_name}
                    // className="gallery-image template_radius "
                  />
                </div>
                <p className="newDis">
                  {item.template_name.length > maxLength
                    ? item.template_name.substring(0, maxLength) + "..."
                    : item.template_name}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default DetailedLogoIdea;
