import React, { useState, useRef, useEffect } from "react";
import crossIcn from "../Assets/Images/crossIcon.png";
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";
import profile from "../Assets/Images/profileImg.svg";
import pen from "../Assets/Images/penIcon.svg";
import { Link, route } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import TabsHeader from "./TabsHeader";
import "../Components/Css/LoggedInHome.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import logoImg from "../Assets/Images/logoImg.png";
import letterhead from "../Assets/Images/LetterHeadImg.png";
import envelop from "../Assets/Images/EnvelopImg.png";
import businessCard from "../Assets/Images/BusinessCard.png";
import InvitationCard from "../Assets/Images/InvitationCard.png";
import otherImg from "../Assets/Images/otherImg.png";

import settingsIcon from "../Assets/Images/threeDotNav.svg";
import downIocn from "../Assets/Images/download_svg.svg";
import duplicateIcon from "../Assets/Images/copy_svg.svg";
import openinEditor from "../Assets/Images/pen_svg.svg";
import deleteSvg from "../Assets/Images/dlt_svg.svg";
import rightArrow from "../Assets/Images/rightIcnn.svg";
import premiumBadge from "../Assets/Images/premiumBadge.svg";
import Infinity from "../Assets/Images/infinity.png";
import Header from "./Header";
import "./Css/ShowLogos.css";
import {
  server_post_data,
  get_home_data,
  delete_user_templates,
  save_update_user_templates_dublicate,
  get_package_by_id,
} from "../ServiceConnection/serviceconnection.js";
import SubscriptionModal from "./SubscriptionModal.js";
import {
  retrieveData,
  removeData,
} from "../LocalConnection/LocalConnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
import { fabric } from "fabric";
import { saveAs } from "file-saver";
let mainCanvas;
let newcanvas;
let refdonwload;
let canvas_temp_download;
const LoggedInHome = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownIndex, setisDropdownIndex] = useState("");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [images2, setimages2] = useState([]);
  const [images3, setimages3] = useState([]);
  const [delete_id, setdelete_id] = useState([]);
  const [transparentBackground, setTransparentBackground] = useState(false);
  const [showImgSizeRange, setshowImgSizeRange] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [logoIndex, setLogoIndex] = useState(0);

  const [isVisible, setIsVisible] = useState(false);
  const [showOverLay, setshowOverLay] = useState(false);

  const [format, setFormat] = useState("");

  const dropdownRef = useRef(null);
  const full_name = retrieveData("full_name");
  const activePackage = retrieveData("active_package");
  const user_id = retrieveData("user_id");
  const business_Card = retrieveData("business_card");
  const package_name = retrieveData("package_name");
  const subscription_active = retrieveData("subscription_active");
  const brand_identity = retrieveData("brand_identity");
  const business_card = retrieveData("business_card");
  const downloading_rights = retrieveData("downloading_rights");
  const resolution = retrieveData("resolution");
  const vector_file = retrieveData("vector_file");
  const transparent_background2 = retrieveData("transparent_background");
  //console.log(resolution);
  //console.log(business_card);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setIsDropdownOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  const toggleDropdown = (index) => {
    setIsDropdownOpen(!isDropdownOpen);
    setisDropdownIndex(index);
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", "1");

    const user_id = retrieveData("user_id");
    fd.append("user_id", user_id);

    try {
      const response = await server_post_data(get_home_data, fd);
      const responseData = response.data;

      const mergedTemplates = [
        ...responseData.brandkit_templates,
        ...responseData.logo_templates,
      ];
      setimages2(mergedTemplates);

      //setimages2(responseData.logo_templates);
      setimages3(responseData.other_templates);

      // Find and store the index of the logo with brandkit_flag == 1
      const logoIndex = getBrandkitFlagIndex(responseData.logo_templates);
      // setLogoIndex(logoIndex)

      setshowLoaderAdmin(false);
    } catch (error) {
      setshowLoaderAdmin(false);
      handleError("network");
    }
  };

  console.log("Index of the logo with brandkit_flag == 1:", logoIndex);

  // Define getBrandkitFlagIndex
  const getBrandkitFlagIndex = (logoTemplates) => {
    return logoTemplates.findIndex((item) => item.brandkit_flag === "1");
  };

  const images1 = [
    { url: logoImg, description: "Logo", route: "/createlogohome" },
    { url: letterhead, description: "Letterhead", route: "/home/Letterhead" },
    { url: envelop, description: "Envelop", route: "/home/Envelops" },
    {
      url: businessCard,
      description: "Business Card",
      route: "/home/Businesscard",
    },
    {
      url: InvitationCard,
      description: "Invitation",
      route: "/home/InvitationCard",
    },
    { url: otherImg, description: "Other", route: "none" },
  ];
  //const images2 = [];
  // const images3 = [
  //   { src: desg1, tag: "lETTERHEAD" },
  //   { src: desg2, tag: "BUSINESS CARD" },
  //   { src: desg3, tag: "INVITATION CARD" },
  //   { src: desg4, tag: "ENVELOP" },
  // ];
  // Pricing condition
  ///console.log("mYdesignData", images3);

  const handleClick = (route) => {
    console.log("handle");
    const businessCard = localStorage.getItem("business_card");

    if (route === "/createlogohome") {
      navigate(route);
    } else if (businessCard === "Business cards") {
      if (route == "none") {
        //updateModalshow(true);
        setshowOthersModal(true);
      } else {
        navigate(route);
      }
    } else {
      updateModalshow(true);
    }
  };

  //delete modal start
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleConfirmDelete = async () => {
    // Perform the delete action
    console.log(delete_id);
    setShowDeleteModal(false);
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", "1");
    fd.append("primary_id", delete_id);
    const user_id = retrieveData("user_id");
    fd.append("user_id", user_id);
    server_post_data(delete_user_templates, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          document.getElementById("div" + delete_id).remove();
        }
      })
      .catch((error) => {
        handleError("network");
      });
  };
  const handleOpenDelete = (primry_id) => {
    // Perform the delete action
    ///console.log(primry_id);
    setdelete_id(primry_id);
    console.log("handle open delete action is hitting");
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  //delete modale end

  // select others options modal start
  const [showOthersModal, setshowOthersModal] = useState(false);

  const handleOpenOthersModal = () => {
    //setshowOthersModal(true);
    updateModalshow(true);
  };
  const handleCloseOthersModal = () => setshowOthersModal(false);
  const handleOpenInEditor = (primary_id) => {
    console.log("primaryIdis", primary_id);
    navigate(`/canvasEdit/logo/${primary_id}`);
  };

  const handleDuplicateOpenInEditor = async (primary_id) => {
    let fd_from = new FormData();
    const user_id = retrieveData("user_id");
    fd_from.append("customer_id", user_id);
    fd_from.append("main_id", "0");
    fd_from.append("primary_id", primary_id);

    //setshowLoaderAdmin(true);
    try {
      const Response = await axios.post(
        save_update_user_templates_dublicate,
        fd_from,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (Response.status === 200) {
        //await server_post_data(save_update_user_templates, fd_from).then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          //handleSuccess(Response.data.message);
          navigate(`/canvasEdit/logo/${Response.data.last_inserted_id}`);
        }
      }
    } catch (error) {
      handleError("network");
    }
  };
  const [updateModal, setupdateModal] = useState(false);
  const updateModalshow = () => {
    setupdateModal(true);
  };
  const handleNavigation = (path) => {
    navigate(path);
  };

  console.log(business_Card);
  // const handleNavigationCondition = (route, business_Card ) => {
  //   console.log("Hello")
  //   if (business_Card === "Business cards") {
  //     console.log("Bye")
  //     navigate(route);
  //   } else {
  //     console.log("Hello")
  //     updateModalshow(true);
  //   }
  // };
  const [DownloadCanvas, setDownloadCanvas] = useState(null);
  const toggleVisibility = (primary_id, logodata) => {
    console.log("clicked");
    setIsVisible(!isVisible);
    setshowOverLay(!showOverLay);
    setDownloadCanvas(logodata);
  };

  const handlesectionDisable = () => {
    setIsVisible(!isVisible);
    setshowOverLay(false);
  };

  const templateparseGradient = (gradientString) => {
    const gradientRegex = /linear-gradient\(([^,]+),(.+)\)/;
    const matches = gradientString.match(gradientRegex);

    if (matches) {
      const direction = matches[1].trim();
      const colors = matches[2].split(",").map((color) => color.trim());
      return { direction, colors };
    }

    // Default case if regex does not match (fallback)
    return { direction: "to right", colors: [gradientString] };
  };

  const setCanvasBackground = (canvas, background) => {
    if (background.startsWith("linear-gradient")) {
      const { direction, colors } = templateparseGradient(background);

      let x1 = 0,
        y1 = 0,
        x2 = 0,
        y2 = 0;
      if (direction.includes("to right")) {
        x2 = canvas.width;
      } else if (direction.includes("to bottom")) {
        y2 = canvas.height;
      } else if (direction.includes("to left")) {
        x1 = canvas.width;
      } else if (direction.includes("to top")) {
        y1 = canvas.height;
      }

      const gradient = new fabric.Gradient({
        type: "linear",
        gradientUnits: "pixels",
        coords: { x1, y1, x2, y2 },
        colorStops: colors.map((color, index) => ({
          offset: index / (colors.length - 1),
          color,
        })),
      });

      canvas.setBackgroundColor(gradient, canvas.renderAll.bind(canvas));
    } else {
      canvas.setBackgroundColor(background, canvas.renderAll.bind(canvas));
    }
  };

  const [rangeValue, setRangeValue] = useState("200");

  useEffect(() => {}, [rangeValue]);
  const createCanvasnew = (logoData) => {
    console.log("rangeValueISSS", rangeValue);
    return new Promise((resolve) => {
      if (!logoData) return;

      let canvasWidth;
      let canvasHeight;
      let canvasOrginalWidth;
      let canvasOrginalHeight;
      let background = "#000";

      canvasWidth = rangeValue;
      canvasHeight = rangeValue;
      background = logoData.background;
      canvasOrginalWidth = logoData.width;
      canvasOrginalHeight = logoData.height;
      canvas_temp_download = new fabric.Canvas(refdonwload, {
        width: canvasWidth,
        height: canvasHeight,
        borderColor: "#000",
        borderWidth: 2,
      });

      setCanvasBackground(canvas_temp_download, background);

      const scaleFactorWidth = canvasWidth / canvasOrginalWidth;
      const scaleFactorHeight = canvasHeight / canvasOrginalHeight;
      const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

      const renderNextObject = async (index) => {
        if (index >= logoData.objects.length) {
          resolve(canvas_temp_download); // Resolve the promise when rendering is complete
          return;
        }
        const objData = logoData.objects[index];
        if (objData.type === "i-text" && objData.metadata) {
          const customWidth = objData.customWidth || objData.width;
          objData.customWidth = customWidth;
        }

        const scaledObject = {
          ...objData,
          scaleX: (objData.scaleX || 1) * scaleFactor,
          scaleY: (objData.scaleY || 1) * scaleFactor,
          left: (objData.left || 0) * scaleFactor,
          top: (objData.top || 0) * scaleFactor,
        };

        fabric.util.enlivenObjects([scaledObject], (objects) => {
          objects.forEach((obj) => {
            obj.selectable = false;
            if (obj.type === "i-text") {
              const customWidth = obj.customWidth || obj.width;
              obj.set({ textAlign: obj.textAlign });
              obj.set({
                width: customWidth,
              });
            }
            canvas_temp_download.add(obj);
          });
          canvas_temp_download.requestRenderAll();
          renderNextObject(index + 1);
        });
      };

      renderNextObject(0);
    });
  };

  const handleRangeChange = (event) => {
    const newValue = event.target.value;
    setRangeValue(newValue);
    console.log(newValue);
  };

  const handleFormatChange = (event) => {
    const selectedFormat = event.target.value;
    setFormat(selectedFormat);
    if (selectedFormat === "png") {
      setshowImgSizeRange(true);
    } else {
      setshowImgSizeRange(false);
    }
  };
  const handleDownload = async (imageSrc) => {
    try {
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      saveAs(blob, imageSrc.split("/").pop());
    } catch (error) {}
  };

  const downloadCanvasTemplate = async () => {
    await createCanvasnew(DownloadCanvas).then((canvasnew) => {
      newcanvas = canvasnew;

      switch (format) {
        case "png":
          downloadAsPNG();
          break;
        case "jpg":
          //downloadAsJPG();
          break;
        case "svg":
          const vectorFile = localStorage.getItem("vector_file");
          if (vectorFile === "Vectors File") {
            downloadAsSVG();
          } else {
            navigate("/pricing");
          }
          break;
        case "pdf":
          downloadAsPDF();
          break;
        default:
          handleDownloadFormetMsg();
      }
    });
  };

  let transparent_background = transparentBackground ? 1 : 0; // Set this dynamically as needed

  const downloadAsPNG = () => {
    const origBackgroundColor = newcanvas.backgroundColor;
    const origWatermarkUrl = "";
    const origWatermarkVisible = newcanvas.backgroundImage?.visible;

    // Temporarily make the watermark invisible
    if (newcanvas.backgroundImage) {
      newcanvas.backgroundImage.visible = false;
      newcanvas.renderAll();
    }

    // Set the background color to transparent if needed
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      const dataURL = newcanvas.toDataURL({
        format: "png",
        quality: 1.0,
        background: "transparent",
      });
      downloadAndSaveImage(dataURL, "image.png");
      newcanvas.setBackgroundColor(origBackgroundColor, () => {
        newcanvas.renderAll();
        if (newcanvas.backgroundImage) {
          newcanvas.backgroundImage.visible = origWatermarkVisible;
          newcanvas.renderAll();
        }
      });
    });
  };

  const downloadAsSVG = () => {
    const origBackgroundColor = newcanvas.backgroundColor;
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    // Set background color to transparent if needed
    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      if (newcanvas) {
        const svg = newcanvas.toSVG({
          reviver: (obj, instance) => {
            if (instance instanceof fabric.Object) {
              return instance.toSVG();
            }
            return obj;
          },
        });
        const blob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "canvas.svg";
        link.click();

        // Clean up
        URL.revokeObjectURL(url);

        // Reset background color
        newcanvas.setBackgroundColor(origBackgroundColor, () => {
          newcanvas.renderAll();
        });
      } else {
        console.error("mainCanvas is undefined, unable to download SVG");
      }
    });
  };

  const downloadAsPDF = () => {
    if (!newcanvas) return;

    const origBackgroundColor = newcanvas.backgroundColor;
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    // Set background color to transparent if needed
    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      const canvasElement = newcanvas;
      const dataURL = canvasElement.toDataURL("image/png");

      const pdf = new jsPDF();
      pdf.addImage(dataURL, "PNG", 0, 0);
      pdf.save("canvas.pdf");

      // Reset background color
      newcanvas.setBackgroundColor(origBackgroundColor, () => {
        newcanvas.renderAll();
      });
    });
  };

  const downloadAndSaveImage = (dataURL, filename) => {
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = filename;
    link.click();
  };
  const handleDownloadFormetMsg = () => {
    console.log("handleDownloadFormetMsgdadadad");
    setModalMessage(true);
  };
  const handleDownloadModlCLose = () => {
    setModalMessage(false);
  };

  return (
    <div className="wrapper" style={{ background: "white" }}>
      <Header />

      <div className="container-lg">
        <TabsHeader />
        <div className="row">
          <div className="">
            <div className="tab1_wrapper">
              <div className="col-12">
                {" "}
                <div className="create_new_wrapper">
                  <h6>Create new...</h6>

                  <div className="image_row_container">
                    <div className="image_row">
                      {images1.map((image, index) => (
                        <div
                          key={index}
                          className="image-item"
                          onClick={
                            (subscription_active == 1 && business_card != "") ||
                            image.route == "/createlogohome"
                              ? () => handleClick(image.route)
                              : handleOpenOthersModal
                          }
                          style={{ position: "relative" }}
                        >
                          <img src={image.url} alt={image.description} />
                          <p>{image.description}</p>

                          {subscription_active == 1 && business_card != "" && (
                            <>
                              <div className="premiumPackImg">
                                <img src={premiumBadge} alt="Star" />
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/*---My Logo */}
              <div className="fav_logos">
                <div className="heading_container">
                  <h6>My Favourite logos</h6>
                  <Link
                    to="/mylogo"
                    className="Heading_link"
                    // onClick={() => setActiveTab(1)}
                  >
                    <u>View all</u>
                  </Link>
                </div>
                <div>
                  <div className="container-lg">
                    <div className="row ImgGap">
                      {images2.map((imagex, index) => (
                        <div
                          key={index}
                          className="col-md-3 col-sm-4 col-6 mb-2 px-1"
                          id={`div${imagex.primary_id}`}
                          onMouseLeave={() => setIsDropdownOpen(false)}
                        >
                          <div className="imageitem">
                            <img
                              src={`https://api.logoacademy.co/assets/${imagex.template_url}`}
                              alt="favorite_logos"
                            />
                            <div
                              className="settings_icon"
                              onClick={() => {
                                toggleDropdown(index);
                              }}
                            >
                              <img
                                src={settingsIcon}
                                alt="settings"
                                className="settings_img"
                              />
                              {isDropdownOpen && isDropdownIndex === index && (
                                <div className="dropdown_menuu HomeLogoTempOption">
                                  <button
                                    onClick={() => {
                                      toggleVisibility(
                                        imagex.primary_id,
                                        JSON.parse(imagex.template_canvas)
                                      );
                                    }}
                                    disabled={
                                      subscription_active == 0 ||
                                      downloading_rights == ""
                                    }
                                  >
                                    <img src={downIocn} alt="downLoadIcon" />
                                    Download
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleDuplicateOpenInEditor(
                                        imagex.primary_id
                                      );
                                    }}
                                  >
                                    <img
                                      src={duplicateIcon}
                                      alt="duplicateIcon"
                                    />
                                    Duplicate Icon
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleOpenInEditor(imagex.primary_id);
                                    }}
                                  >
                                    <img
                                      src={openinEditor}
                                      alt="openinEditor"
                                    />
                                    Open in editor
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleOpenDelete(imagex.primary_id);
                                    }}
                                    disabled={imagex.brandkit_flag == 1}
                                    style={{
                                      cursor:
                                        imagex.brandkit_flag == 1
                                          ? "not-allowed"
                                          : "pointer",
                                    }}
                                  >
                                    <img src={deleteSvg} alt="deleteSvg" />
                                    Delete
                                  </button>
                                </div>
                              )}
                            </div>
                            {imagex.brandkit_flag == 1 &&
                              subscription_active == 1 &&
                              brand_identity != "" && (
                                <div className="infintyDiv">
                                  <img src={Infinity} alt="infinity Icon" />
                                  <p>BRAND KIT</p>
                                </div>
                              )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {showOverLay && (
                <div
                  className={`overLayCLass ${isVisible ? "show" : ""}`}
                ></div>
              )}
              <div
                className={`downloadTemplates ${isVisible ? "visible" : ""}`}
              >
                <div className="crossDownloadicon">
                  <button
                    onClick={handlesectionDisable}
                    style={{ background: "transparent", border: "none" }}
                  >
                    <img src={crossIcn} alt="cross img"></img>
                  </button>
                </div>

                <div className="container">
                  <ul>
                    <li>
                      <div className="firstResize">
                        <div className="HeadingDownload">
                          <div className="d-flex gap-2 alignment-items-center">
                            <p>Download</p>
                            <span>Package: {package_name}</span>
                          </div>
                        </div>
                        <div className="w-100 mt-2">
                          <select value={format} onChange={handleFormatChange}>
                            <option value="" disabled hidden>
                              Select format
                            </option>
                            <option value="png">PNG</option>

                            <option
                              value="svg"
                              disabled={
                                subscription_active == 0 || vector_file == ""
                              }
                            >
                              SVG
                            </option>
                            <option value="pdf">PDF</option>
                          </select>
                        </div>
                        {showImgSizeRange && (
                          <>
                            <div
                              className="imgHead"
                              style={{ paddingTop: "1rem" }}
                            >
                              <label>Image size</label>
                              <span>
                                {rangeValue}x{rangeValue}px
                              </span>
                            </div>
                            <div className="downloadRenge">
                              <input
                                className="rngePIXL"
                                type="range"
                                min="200"
                                max="2000"
                                step="5"
                                defaultValue={rangeValue}
                                onChange={(e) => handleRangeChange(e)}
                                disabled={
                                  subscription_active == 0 || resolution == ""
                                }
                              />
                            </div>
                          </>
                        )}
                        <div className="downloadRenge2">
                          <input
                            type="checkbox"
                            id="transparentBg"
                            name="transparentBg"
                            checked={transparentBackground}
                            onChange={(e) => {
                              setTransparentBackground(e.target.checked);
                              console.log(
                                "Checkbox checked:",
                                e.target.checked
                              );
                            }}
                            disabled={
                              subscription_active == 0 ||
                              transparent_background2 == ""
                            }
                          />
                          <label> Transparent Background</label>
                        </div>{" "}
                        <div className="col">
                          <div className="downloadTemplateBtnn">
                            <button
                              onClick={downloadCanvasTemplate}
                              disabled={
                                subscription_active == 0 ||
                                downloading_rights == ""
                              }
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              {/*---My design */}
              <div className="myDesigns">
                <div className="heading_container">
                  <h6>My Designs</h6>
                  <Link
                    to="/mydesigns"
                    className="Heading_link"
                    onClick={() => setActiveTab(2)}
                  >
                    <u>View all</u>
                  </Link>
                </div>
                <div>
                  <div className="container">
                    <div className="row p-0 ImgGap">
                      {images3.map((imagex, index) => (
                        <div
                          key={index}
                          className="col-12 col-md-6 col-lg-4 p-0  imageitem p-0"
                          id={`div${imagex.primary_id}`}
                        >
                          <div className="image_tag">
                            {imagex.template_type}
                          </div>
                          <img
                            src={`https://api.logoacademy.co/assets/${imagex.template_url}`}
                            alt={imagex.template_type}
                          />
                          <div
                            className="settings_icon"
                            onClick={() => {
                              toggleDropdown(index);
                            }}
                            // ref={dropdownRef}
                          >
                            <img
                              src={settingsIcon}
                              alt="settings"
                              className="settings_img"
                            />
                            {isDropdownOpen && isDropdownIndex === index && (
                              <div className="dropdown_menuu HomeLogoTempOption">
                                <button
                                  onClick={() => {
                                    toggleVisibility(
                                      imagex.primary_id,
                                      JSON.parse(imagex.template_canvas)
                                    );
                                  }}
                                  disabled={
                                    subscription_active == 0 ||
                                    downloading_rights == ""
                                  }
                                >
                                  <img src={downIocn} alt="downLoadIcon" />
                                  Download
                                </button>

                                <button
                                  onClick={() => {
                                    handleDuplicateOpenInEditor(
                                      imagex.primary_id
                                    );
                                  }}
                                >
                                  <img
                                    src={duplicateIcon}
                                    alt="duplicateIcon"
                                  />
                                  Duplicate Icon
                                </button>
                                <button
                                  onClick={() => {
                                    handleOpenInEditor(imagex.primary_id);
                                    console.log("sdfsdfsfsfsfsdfsdfsdfsd");
                                  }}
                                >
                                  <img src={openinEditor} alt="openinEditor" />
                                  Open in editor
                                </button>
                                <button
                                  onClick={() => {
                                    handleOpenDelete(imagex.primary_id);
                                  }}
                                >
                                  <img src={deleteSvg} alt="deleteSvg" />
                                  Delete
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* delete design modal */}
      <Modal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        className="delete_modal_wrapper fade "
        centered
      >
        <div className="delete_modal">
          <Modal.Body className="delete_modal_bodycontainer">
            <div className="delete_modal_body">
              <h5> Are you sure you want to delete that design?</h5>
              <h6>Design will be removed from your list</h6>
              <div className="delete_modal_buttons TemplateDelete">
                <button
                  onClick={() => {
                    handleCloseDeleteModal();
                  }}
                  className="ceneclBtnTemp"
                >
                  Cancel
                </button>

                <button
                  onClick={() => {
                    handleConfirmDelete();
                    setShowDeleteModal(false);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      {/* other option modal */}
      <Modal
        show={showOthersModal}
        onHide={handleCloseOthersModal}
        className="others_modal_wrapper fade modal-sm"
        centered
      >
        <div className="others_modal">
          <Modal.Header closeButton>
            <h5>
              <Link to="/createlogohome"></Link>Create New Designs
            </h5>
          </Modal.Header>
          <Modal.Body className="others_modal_bodycontainer ">
            <div className="others_modal_body">
              <ul>
                <li>
                  <button onClick={() => handleNavigation("/createlogohome")}>
                    {" "}
                    Logo <img src={rightArrow} alt="right arrow" />
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleNavigation("/home/Businesscard")}
                  >
                    Business Card <img src={rightArrow} alt="right arrow" />
                  </button>
                </li>
                <li>
                  <button onClick={() => handleNavigation("/home/Envelops")}>
                    Envelop <img src={rightArrow} alt="right arrow" />
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleNavigation("/home/InvitationCard")}
                  >
                    Invitation Card <img src={rightArrow} alt="right arrow" />
                  </button>
                </li>
                <li>
                  <button onClick={() => handleNavigation("/home/Letterhead")}>
                    Letter Head <img src={rightArrow} alt="right arrow" />
                  </button>
                </li>
              </ul>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <SubscriptionModal
        handleModal={setupdateModal}
        updateModal={updateModal}
      />

      {/**-----------download modall----*/}
      <Modal
        className="deleteModddl"
        show={modalMessage}
        onHide={handleDownloadModlCLose}
        centered
      >
        <Modal.Header closeButton>
          <div className="WarningHeader">Warning</div>
        </Modal.Header>
        <Modal.Body>
          <div className="DownloadFormetErrorMsg">
            <i className="fas fa-exclamation-triangle warning-icon"></i>{" "}
            Unsupported format. Please choose png, jpg, svg, or pdf.
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LoggedInHome;
