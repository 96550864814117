import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import successPaymentGif from "../Assets/Images/successPaymentGif.gif";
import "../Components/Css/Pricing.css";
import Header from "./Header.js";
import PaymentInvoice from "./PaymentInvoice.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  get_package_by_customer_id,
  get_package_by_id,
} from "../ServiceConnection/serviceconnection.js";
import { storeData, retrieveData } from "../LocalConnection/LocalConnection.js";

import { Link, useParams } from "react-router-dom";
const CompletePayment = () => {
  const user_id = retrieveData("user_id");

  const payment_transaction_id = retrieveData("client_secret");
  const [SubscriptDeatails, SetSubscriptDeatails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Custumerinfo, SetCustumerinfo] = useState([]);

  useEffect(() => {
    const master_data_get_user_data = async () => {
      setLoading(true);
      const fd = new FormData();
      fd.append("customer_id", user_id);

      try {
        const userResponse = await server_post_data(
          get_package_by_customer_id,
          fd
        );
        setLoading(false);
        if (userResponse.data.error) {
          handleError(userResponse.data.message);
        } else {
          SetSubscriptDeatails(userResponse.data.message);
          SetCustumerinfo(userResponse.data.message);
          storeSubscriptionData(userResponse.data.message);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        handleError("network");
      }
    };
    const storeSubscriptionData = (data) => {
      const details = data;
      console.log(details);
      storeData("Price", details.Price);
      storeData("brand_identity", details.brand_identity);
      storeData("business_card", details.business_card);
      storeData("copyright_ownership", details.copyright_ownership);
      storeData("downloading_rights", details.downloading_rights);
      storeData("favicon", details.favicon);
      storeData("file_example_download", details.file_example_download);
      storeData("new_version_for_logo", details.new_version_for_logo);
      storeData("package_days", details.package_days);
      storeData("package_name", details.package_name);
      storeData("primary_id", details.primary_id);
      storeData("resizing_design", details.resizing_design);
      storeData("resolution", details.resolution);
      storeData("support", details.support);
      storeData("transparent_background", details.transparent_background);
      storeData("unlimited_changes", details.unlimited_changes);
      storeData("vector_file", details.vector_file);
      storeData("watermark", details.watermark);
    };
    master_data_get_user_data();
  }, [payment_transaction_id]);

  const handleDownloadinvoice = () => {
    const invoiceDiv = document.querySelector(".PayemntIncoiceDiv");

    // Clone the invoice div
    const clonedInvoiceDiv = invoiceDiv.cloneNode(true);
    document.body.appendChild(clonedInvoiceDiv);

    // Move the cloned div off-screen
    clonedInvoiceDiv.style.position = "absolute";
    clonedInvoiceDiv.style.left = "-10000px";
    clonedInvoiceDiv.style.display = "block";

    // Generate the PDF from the cloned div
    const input = clonedInvoiceDiv.querySelector("#invoice");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position -= pageHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("invoice.pdf");

      // Remove the cloned div after generating the PDF
      document.body.removeChild(clonedInvoiceDiv);
    });
  };
  return (
    <>
      <Header Showheader={false} />
      <section
        className="logoideas_section logoideas_section_pricingPage   ComplatePymentDiv "
        style={{ background: "white" }}
      >
        <div className="container">
          <div
            className="container d-flex justify-content-center align-items-center compPyTOp "
            style={{ background: "white" }}
          >
            <div className=" text-center animate__animated animate__zoomIn  CarDContainer">
              <div className="PaymentContainer">
                <div className="TitleCOn">
                  <img src={successPaymentGif} alt="mark"></img>{" "}
                  <h1>Success!</h1>
                  <label>Your request has been processed successfully</label>
                  <p>You receive a notification shortly in your email</p>
                </div>

                <div
                  className="contentPymnt"
                  style={{ flexWrap: "wrap", gap: "0.5rem" }}
                >
                  <button
                    style={{ marginRight: "0.3rem", whiteSpace: "nowrap" }}
                    onClick={handleDownloadinvoice}
                  >
                    Download Invoice
                  </button>

                  <Link to="/">
                    <button className="" style={{ whiteSpace: "nowrap" }}>
                      {" "}
                      Back to Home
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="PayemntIncoiceDiv" style={{ display: "none" }}>
          {SubscriptDeatails &&
            Custumerinfo &&
            Custumerinfo.data &&
            SubscriptDeatails.data && (
              <PaymentInvoice
                handleDownloadinvoice={handleDownloadinvoice}
                datasubscript={SubscriptDeatails.data}
                datacustomer={Custumerinfo.data}
              />
            )}
        </div>
      </section>
      {/* --------------footer---------------*/}
      {/* <Footer /> */}
    </>
  );
};

export default CompletePayment;
