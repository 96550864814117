import React, { useState, useEffect } from "react";
import "./Css/HowitWorks.css";

import Header from "./Header";
import whyLA02 from "../Assets/Images/Group 1272628756.svg";
import whyLA04 from "../Assets/Images/Group 1272628757.svg";
import whyLA03 from "../Assets/Images/Group 1272628758.svg";
import whyLA01 from "../Assets/Images/Group 1272628759.svg";
import HowRight from "../Assets/assests/girlPen.png";
import HowLeft from "../Assets/assests/boyLaptop.png";
//footer imports
import { Link } from "react-router-dom";

import Footer from "./Footer";
import { data_logo_ideas } from "../CommonJquery/WebsiteText";
import { handleLinkClick } from "../CommonJquery/CommonJquery";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  get_all_industry_data,
} from "../ServiceConnection/serviceconnection.js";
const HowitWorks = () => {
  // footer language selector

  //logo ideas for every section array
  useEffect(() => {
    master_data_get("0", "0");
  }, []);
  const [searchTerm, setSearchTerm] = useState("");
  const [templateData, setTemplateData] = useState([]);
  const [FiltertemplateData, setFiltertemplateData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const master_data_get = async (flag, call_id) => {
    const fd = new FormData();
    setshowLoaderAdmin(true);
    fd.append("flag", flag);
    fd.append("call_id", call_id);

    await server_post_data(get_all_industry_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setTemplateData(Response.data.message.data);
          setFiltertemplateData(Response.data.message.data);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.error(error);
        setshowLoaderAdmin(false);
      });
  };

  console.log(FiltertemplateData);

  return (
    <>
      <Header />
      <div className="howitworks_wrapper">
        <div className="headerSection_howitWorkss">
          <div className="absoluteImages">
            <div className="center_text_wrapperr">
              <h3>
                Want to Create a Logo Online?
                <br />
                Just Type Your Business Name and Follow The Simple Steps!
              </h3>
              <Link to="/createLogoHome">
                <button>Create A Logo</button>
              </Link>
            </div>
          </div>
          <img className="leftImageHow" src={HowLeft} alt="How It Works" />
          <img className="rightImageHow" src={HowRight} alt="How It Works" />
        </div>
      </div>
      <div className="container stepsContaine mt-2">
        <div className="row1 row">
          {/* card1  */}
          <div className="white-card col-lg-6 col-md-6">
            <div className="card-content">
              <h2 className="card-heading">
                <img src={whyLA01} alt="whyLA01" />
                Select Logo Templates
              </h2>
              <p className="card-subheading">
                Select logo icons and colours that you like. LogoAcademy will
                use them as inspiration to generate more logo templates.
              </p>
            </div>
          </div>
          {/* card2 */}
          <div className="white-card col-lg-6 col-md-6">
            <div className="card-content">
              <h2 className="card-heading">
                <img src={whyLA02} alt="whyLA01" />
                Choose A Design
              </h2>
              <p className="card-subheading">
                After selecting the options, you will see thousands of logo
                templates designed specially for you. Just scroll the page to
                select your favourite one.
              </p>
            </div>
          </div>
        </div>
        <div className="row2 row">
          {/* card3  */}
          <div className="white-card col-lg-6 col-md-6">
            <div className="card-content">
              <h2 className="card-heading">
                <img src={whyLA03} alt="whyLA01" />
                Customise Design
              </h2>
              <p className="card-subheading">
                Select suitable colours, typography, icons and other design
                elements. Unlimited free editing and modification are available
                for your design.
              </p>
            </div>
          </div>
          {/* card4  */}
          <div className="white-card col-lg-6 col-md-6">
            <div className="card-content">
              <h2 className="card-heading">
                {" "}
                <img src={whyLA04} alt="whyLA01" />
                Download Logo Files
              </h2>
              <p className="card-subheading">
                Download all the provided by LogoAcademy. Use these files for
                print, on your website and in other places as per business
                requirements.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="logoideas_section howitWorks_logoideas_section">
        <div className="container">
          <div className="howitworks_header_section">
            <div className="howitworks_main_heading">
              <h3>Logo ideas for every industry</h3>
            </div>
            <div className="col-sm-12">
  <ul className="multi-column-list row">
    {FiltertemplateData.map((item, index) => (
      <div className="col-lg-3 col-md-4 col-6" key={index}>
        <div>
          <li>
            <Link to={`/logoideas/${item.primary_id}`}>
              {item.industry_name}
            </Link>
          </li>
        </div>
      </div>
    ))}
  </ul>
</div>
          </div>
          <div className="logo_ideas_button">
            <div className="more_logos_button more_logos_button_howitworks">
              <button onClick={() => handleLinkClick("/logoIdeas")}>
                All logo ideas
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default HowitWorks;
