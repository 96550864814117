import React, { useState, useEffect, useRef } from "react";
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";
import crossIcn from "../Assets/Images/crossIcon.png";
import transpretLoader from "../Assets/Images/transpretLoader.gif";
import axios from "axios";
import $ from "jquery";
import IconLoader from "./IconLoader.js";
import Heart from "../Assets/Images/heart.svg";

import hearticonRed from "../Assets/Images/heart2Red.svg";
import "../Components/Css/ShowLogos.css";
import plus from "../Assets/Images/plusIcon.svg";
import Download from "../Assets/Images/downArrow.svg";
import topArrow from "../Assets/Images/topArrow.svg";
import tickmark from "../Assets/Images/TickImg3.svg";
import Delet from "../Assets/Images/deletWhite.png";
import ShowLogoLoader from "./ShowLogoLoader.js";
import { Modal } from "react-bootstrap";
import { fabric } from "fabric";

import {
  server_post_data,
  get_admin_template_by_id,
  get_icon_img,
  call_noun_project_api_id,
  save_update_user_templates,
  delete_user_templates,
  get_admin_template_showLogos,
} from "../ServiceConnection/serviceconnection.js";
import { retrieveData, storeData } from "../LocalConnection/LocalConnection.js";
import { handleError } from "../CommonJquery/CommonJquery";
import searchIcon from "../Assets/Images/searchIcon.svg";
import LoderIcon from "./IconLoader.js";
import "./Css/Header.css";

import Header from "./Header.js";
let iconselect = [];
let iconselect2;
let iincment = 1000;
let iincment2 = 0;
let currentindex;
let WishlistTemp = 0;
let templtarray = {};
let backgrondclr = {};
let loadingStates = {};
let numberOfObjects = 0;
let newcanvas;
let refdonwload;
let canvas_temp_download;
const ShowLogos = () => {
  const navigate = useNavigate();
  const user_id = retrieveData("user_id");
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [GetIcon, SetIconImg] = useState([]);
  const [iconsRelated, seticonsRelated] = useState([]);
  const selectedIcons = JSON.parse(retrieveData("selectedIcons"));
  const [rotatePlus1, setRotatePlus1] = useState(false);
  const [rotatePlus2, setRotatePlus2] = useState(false);
  //drop down menus
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [next_page, setnext_page] = useState("");
  const [allResponses, setallResponses] = useState([]);

  const [offset, setOffset] = useState(0); // Current offset for pagination
  const [hasMore, setHasMore] = useState(true); // Whether more icons are available
  const [loadingMore, setLoadingMore] = useState(false); // Loading state for "Load More" button
  const [noData, setNoData] = useState(false); // Flag to indicate no data found
  const random = (max, min) =>
    Math.floor(Math.random() * (max - min + 1)) + min;
  const [logos, setLogos] = useState([]);
  const [loadingStates, setLoadingStates] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const scrollContainerRef = useRef(null);
  const vectorFile = localStorage.getItem("vector_file");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [cardColors, setCardColors] = useState([]);
  const [showLoaderAdmin2, setShowLoaderAdmin2] = useState(true);

  const [transparentBackground, setTransparentBackground] = useState(false);
  const [showImgSizeRange, setshowImgSizeRange] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [rangeValue, setRangeValue] = useState("200");
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  // Initial value

  // const [startIndex, setstartIndex] = useState(0);
  // const [endIndex, setendIndex] = useState(15);

  const startIndexRef = useRef(0);
  const endIndexRef = useRef(15);

  useEffect(() => {
    // Retrieve the value from local storage
    const storedIndustry = localStorage.getItem("selectedIndustry");

    if (storedIndustry) {
      const industryObject = JSON.parse(storedIndustry);
      setSelectedIndustry(industryObject.label);
    }
  }, [selectedIndustry]);

  useEffect(() => {
    console.log("ho");

    // Retrieve card colors from storage
    const cardColorsString = retrieveData("card_colors");

    // Parse card colors and ensure there are 3 elements
    let cardColors = [];
    if (cardColorsString) {
      try {
        cardColors = JSON.parse(cardColorsString);
      } catch (error) {
        console.error("Error parsing card colors from localStorage:", error);
      }
    }

    while (cardColors.length < 3) {
      cardColors.push("");
    }

    let mty = cardColors;
    setCardColors(cardColors);

    // Call API with retrieved data
    setTimeout(() => {
      if (!user_id) {
        var event = new CustomEvent("customEvent");
        document
          .getElementById("login_check_jquery_hide_data")
          .dispatchEvent(event);
        return;
      }

      console.log("ho2");
      master_data_get(selectedIndustry, mty, 0, 0);
    }, 500);

    // Call function to generate color palettes with parsed array
    // generateColorPalettes(cardColors);
  }, [selectedIndustry]);

  //const colorsSaved = retrieveData("card_colors");
  const whiteColor = ["", "", ""];
  const [color, setColor] = useState(whiteColor);

  const retrievedColor = () => {
    const colorsSaved = retrieveData("card_colors");
    if (colorsSaved && colorsSaved.length > 0) {
      try {
        const parsedColors = JSON.parse(colorsSaved);
        while (parsedColors.length < 3) {
          parsedColors.push("");
        }
        let mty = parsedColors;
        // Set the parsed colors to state
        setColor(mty);
      } catch (error) {
        console.error("Failed to parse color data:", error);
      }
    } else {
      // Set default colors
      storeData("card_colors", JSON.stringify(whiteColor));
      setColor(whiteColor);
    }
  };

  useEffect(() => {
    retrievedColor();
  }, []);
  useEffect(() => {
    const suggestionsLinks = retrieveData("related_industry");
    seticonsRelated(suggestionsLinks);

    // Retrieve selected icons from local storage on component mount
    const selectedIconsFromStorage = JSON.parse(retrieveData("selectedIcons"));
    if (selectedIconsFromStorage) {
      setSelectedSVGs(selectedIconsFromStorage);
      console.log(selectedIconsFromStorage);

      let svgDataUrl;
      selectedIconsFromStorage.forEach((icon) => {
        iconselect.push(icon.iconselect);
      });
    }
  }, []);

  const elements =
    iconsRelated && typeof iconsRelated === "string"
      ? iconsRelated.split(",")
      : [];

  const resultArray = elements.map((element) => ({ label: element }));

  const columnRef = useRef(null);
  const [colWidth, setColWidth] = useState(0);

  const updateColumnWidth = () => {
    if (columnRef.current) {
      const columnWidth = columnRef.current.clientWidth;
      let divisionFactor = 4;
      let canvasWidth;

      if (window.innerWidth < 350) {
        canvasWidth = 230;
      } else if (window.innerWidth < 380) {
        canvasWidth = 150;
      } else if (window.innerWidth < 450) {
        canvasWidth = 200;
      } else {
        if (window.innerWidth < 991 && window.innerWidth > 768) {
          divisionFactor = 3;
        } else if (window.innerWidth < 768 && window.innerWidth > 576) {
          divisionFactor = 2;
        } else if (window.innerWidth < 350) {
          divisionFactor = 1;
        } else if (window.innerWidth < 576) {
          divisionFactor = 2;
        } else {
          divisionFactor = 4;
        }
        canvasWidth = columnWidth / divisionFactor;
      }
      setColWidth(canvasWidth);
    }
  };

  useEffect(() => {
    updateColumnWidth();
    window.addEventListener("resize", updateColumnWidth);
    return () => {
      window.removeEventListener("resize", updateColumnWidth);
    };
  }, []);

  const handleColorItemClick = (hexColor) => {
    setIsChanged(true);
    const colorName = colorMapping[hexColor];
    const newColors = [...color];
    const index = newColors.indexOf(colorName);
    if (index !== -1) {
      // If already selected, deselect it
      newColors[index] = ""; // Set the deselected color to an empty string
    } else {
      // If not selected, check for empty slots and add
      const emptyIndex = newColors.indexOf(""); // Check for an empty slot
      if (emptyIndex !== -1) {
        newColors[emptyIndex] = colorName; // Add to an empty slot
      } else if (newColors.length < 3) {
        newColors.push(colorName); // Add to the end if less than 3 colors
      }
    }

    // Shift colors to the left to fill empty slots
    for (let i = 0; i < newColors.length - 1; i++) {
      if (newColors[i] === "") {
        newColors[i] = newColors[i + 1];
        newColors[i + 1] = "";
      }
    }
    // Repeat shifting to ensure all empty slots are filled correctly
    for (let i = 0; i < newColors.length - 1; i++) {
      if (newColors[i] === "") {
        newColors[i] = newColors[i + 1];
        newColors[i + 1] = "";
      }
    }
    const fillcolor = newColors.filter((c) => c !== "").length;
    // Hide dropdown and set state
    if (fillcolor == 3) {
      setShowDropdown1(false);
      setRotatePlus1(false);
    }
    setColor(newColors);
    localStorage.setItem("card_colors", JSON.stringify(newColors));
  };

  const handleDeleteColor = (event, index) => {
    event.preventDefault();
    setIsChanged(true);

    const newColors = [...color];
    newColors[index] = ""; // Set the color to an empty string

    for (let i = 0; i < newColors.length - 1; i++) {
      if (newColors[i] === "") {
        newColors[i] = newColors[i + 1];
        newColors[i + 1] = "";
      }
    }
    for (let i = 0; i < newColors.length - 1; i++) {
      if (newColors[i] === "") {
        newColors[i] = newColors[i + 1];
        newColors[i + 1] = "";
      }
    }

    setColor(newColors);
    localStorage.setItem("card_colors", JSON.stringify(newColors));
    return false;
  };

  const colorMapping = {
    "#888": "Black",
    "#00f": "Blue",
    "#0ff": "Teal",
    "#89d587": "Green",
    "#764bc4": "Purple",
    "#f0f": "Pink",
    "#f06060": "Red",
    "#ee8133": "Orange",
    "#f8e8a2": "Yellow",
  };
  const colorItems = [
    { color: "#f06060", className: "color_item7", Red: 0, 0: "Red" },
    { color: "#89d587", className: "color_item4", Green: 1, 1: "Green" },
    { color: "#888", className: "color_item1", Black: 2, 2: "Black" },
    { color: "#ee8133", className: "color_item8", Orange: 3, 3: "Orange" },
    { color: "#0ff", className: "color_item3", Teal: 4, 4: "Teal" },
    { color: "#f8e8a2", className: "color_item9", Yellow: 5, 5: "Yellow" },
    { color: "#f0f", className: "color_item6", Pink: 6, 6: "Pink" },
    { color: "#764bc4", className: "color_item5", Purple: 7, 7: "Purple" },
    { color: "#00f", className: "color_item2", Blue: 8, 8: "Blue" },
  ];

  const dropdownRef2 = useRef(null);
  const logosGridRef = useRef(null);
  const plusRef = useRef(null);
  const plusRef1 = useRef(null);
  const dropdownRef1 = useRef(null);
  const rotateDropdown1 = (event) => {
    event.preventDefault();
    setShowDropdown1(!showDropdown1);
    setRotatePlus1(!rotatePlus1);

    setShowDropdown2(false);
    setRotatePlus2(false);
  };
  const rotateDropdown2 = (event) => {
    event.preventDefault();
    setShowDropdown2(!showDropdown2);
    setRotatePlus2(!rotatePlus2);

    setShowDropdown1(false);
    setRotatePlus1(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (dropdownRef1.current &&
          !dropdownRef1.current.contains(event.target) &&
          !plusRef1.current.contains(event.target) &&
          showDropdown1) ||
        (dropdownRef2.current &&
          !dropdownRef2.current.contains(event.target) &&
          !plusRef.current.contains(event.target) &&
          showDropdown2)
      ) {
        setShowDropdown1(false);
        setRotatePlus1(false);
        setShowDropdown2(false);
        setRotatePlus2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showDropdown1, showDropdown2]);
  let canvasRefs = useRef([]);

  const clearCanvasRefs = () => {
    canvasRefs.current.forEach((ref) => {
      if (ref && ref.getContext) {
        const ctx = ref.getContext("2d");
        ctx.clearRect(0, 0, ref.width, ref.height);
      }
    });
    canvasRefs.current = []; // Reset the refs array
  };

  const handleScrollToBottom = () => {
    if (numberOfObjects == 1) {
      console.log(numberOfObjects);
      setShowLoaderAdmin2(true);
      // Fetch selectedIndustry from localStorage
      const storedIndustry = localStorage.getItem("selectedIndustry");
      let selectedIndustry = "";
      if (storedIndustry) {
        try {
          const industryObject = JSON.parse(storedIndustry);
          selectedIndustry = industryObject.label; // Get the label from the stored object
        } catch (error) {
          console.error(
            "Error parsing selectedIndustry from localStorage:",
            error
          );
          selectedIndustry = ""; // Fallback to an empty string or handle it accordingly
        }
      } else {
        setShowLoaderAdmin2(false);
      }

      // Fetch card_colors from localStorage
      const cardColorsStr = localStorage.getItem("card_colors");
      let cardColorsArray = [];
      if (cardColorsStr) {
        try {
          cardColorsArray = JSON.parse(cardColorsStr) || [];
        } catch (error) {
          console.error("Error parsing card_colors from localStorage:", error);
          cardColorsArray = [];
        }
      }

      const cardColors = cardColorsArray.join(",");

      console.log(selectedIndustry);
      master_data_get(selectedIndustry, cardColors, "0", "loadmore");
    } else {
      console.log("dfgfdgfdg");
    }
  };

  useEffect(() => {
    const container = logosGridRef.current;

    let hasReachedBottom = false;

    const handleScroll = () => {
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const isBottom = scrollTop + clientHeight >= scrollHeight - 5; // Small threshold

        if (isBottom && !hasReachedBottom) {
          handleScrollToBottom();
          hasReachedBottom = true; // Mark that we've reached the bottom
        } else if (!isBottom && hasReachedBottom) {
          // If the user scrolls up, reset the flag
          hasReachedBottom = false;
        }
      }
    };

    // Add scroll event listener
    container.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const master_data_get = async (
    templateIndustry,
    templateColors,
    templateIcon,
    loadmore
  ) => {
    const fd = new FormData();
    if (loadmore !== "loadmore") {
      setshowLoaderAdmin(true);
      clearCanvasRefs(); // Clear existing canvas refs before fetching new data
      setLogos("");
      fd.append("loadmore", loadmore);
    } else {
      // If it's a load more request, increment the indices manually using refs
      startIndexRef.current += 15;
      endIndexRef.current += 15;
    }

    fd.append("template_industry", templateIndustry);
    if (templateColors !== 1) {
      fd.append("template_colors", templateColors);
    } else {
      fd.append("template_colors", color);
    }
    fd.append("template_icon", templateIcon);
    fd.append("industry_id", "0");

    fd.append("start_index", startIndexRef.current);
    fd.append("end_index", endIndexRef.current);
    numberOfObjects = 0;
    try {
      const response = await server_post_data(get_admin_template_showLogos, fd);
      const responseData = response.data.message.data;

      numberOfObjects = responseData.length;
      console.log("Number of objects:", numberOfObjects);
      if (numberOfObjects >= 15) {
        numberOfObjects = 1;
      } else {
        numberOfObjects = 0;
        setShowLoaderAdmin2(false);
      }

      response.data.message.custom_font.forEach((font) => {
        const fontFace = new FontFace(font.family, `url(${font.src_file})`);

        fontFace
          .load()
          .then((loadedFace) => {
            document.fonts.add(loadedFace);
          })
          .catch((error) => {
            console.error(`Error loading font "${font.family}":`, error);
          });
      });

      const selectedIconsFromStorage = selectedSVGs;
      if (selectedIconsFromStorage.length !== 0) {
        iconselect = [];
        selectedIconsFromStorage.forEach((icon) => {
          iconselect.push(icon.iconselect);
        });
      }

      // Filter and map fonts for style tag
      const fontStyles = response.data.message.fontlist
        .filter((font) => font.files && font.files.regular)
        .map((font) => {
          return `@font-face {
            font-family: '${font.family}';
            font-style: normal;
            font-weight: 400;
            src: url(${font.files.regular});
          }`;
        })
        .join("\n");

      const styleElement = document.createElement("style");
      styleElement.textContent = fontStyles;
      document.head.appendChild(styleElement);

      if (loadmore === "loadmore") {
        setLogos((prevIcons) => [...prevIcons, ...responseData]);
      } else {
        setLogos(responseData);
      }
      setshowLoaderAdmin(false);
    } catch (error) {
      setshowLoaderAdmin(false);
      handleError("network");
    }
  };
  const master_data_get_icons = async (iconIdsArray) => {
    return master_data_get_icons;
    setshowLoaderAdmin(true); // Assuming this sets a loader state for UI indication

    try {
      // Array to store all response data
      const allResponses = [];

      // Iterate over each icon_id in the array
      for (let i = 0; i < iconIdsArray.length; i++) {
        const icon_id = iconIdsArray[i];

        // Create FormData and append icon_id
        const fd = new FormData();
        fd.append("icon_id", icon_id);

        // Make API call
        try {
          const response = await server_post_data(call_noun_project_api_id, fd);
          const responseData = response.data.icon.icon_url;

          // Store responseData in allResponses array
          allResponses.push(responseData);
        } catch (error) {
          console.error(`Error fetching data for icon_id ${icon_id}:`, error);
          // Handle individual API call error if needed
        }
      }

      // Log all response data to console after all API calls are completed

      // Optionally, you can set state or perform further actions here

      setshowLoaderAdmin(false); // Hide loader after API calls are complete

      setallResponses(allResponses);
      return allResponses;
    } catch (error) {
      console.error("Error in master_data_get_icons function:", error);
      setshowLoaderAdmin(false); // Hide loader in case of error
      handleError("network"); // Handle error (assuming handleError function is defined)
    }
  };
  const parseGradient = (gradientString) => {
    const gradientRegex = /linear-gradient\(([^,]+),(.+)\)/;
    const matches = gradientString.match(gradientRegex);

    if (matches) {
      const direction = matches[1].trim();
      const colors = matches[2].split(",").map((color) => color.trim());
      return { direction, colors };
    }

    // Default case if regex does not match (fallback)
    return { direction: "to right", colors: [gradientString] };
  };

  const replaceColorInSVG = (svgData, oldColor, newColor) => {
    // Replace the old color with the new color where fill exists
    let modifiedSvgData = svgData.replace(
      new RegExp(`fill\\s*=\\s*["']${oldColor}["']`, "gi"),
      `fill="${newColor}"`
    );

    // Add fill attribute where it's missing
    modifiedSvgData = modifiedSvgData.replace(
      /(<(svg)[^>]*)(>)/gi,
      (match, p1, p2, p3) => {
        if (!/fill\s*=/.test(match)) {
          // If no fill attribute is present, add it
          return `${p1} fill="${newColor}"${p3}`;
        }
        return match;
      }
    );

    return modifiedSvgData;
  };

  const getFillColorFromSVG = (svgData) => {
    // Create a DOM parser to parse the SVG string
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgData, "image/svg+xml");

    // Get the <svg> element
    const svgElement = doc.querySelector("svg");
    if (!svgElement.hasAttribute("fill")) {
      // Set the default fill color if not present
      svgElement.setAttribute("fill", "#000000");
    }
    // Return the fill color from the <svg> element
    return svgElement ? svgElement.getAttribute("fill") : null;
  };

  const templateparseGradient = (gradientString) => {
    const gradientRegex = /linear-gradient\(([^,]+),(.+)\)/;
    const matches = gradientString.match(gradientRegex);

    if (matches) {
      const direction = matches[1].trim();
      const colors = matches[2].split(",").map((color) => color.trim());
      return { direction, colors };
    }

    // Default case if regex does not match (fallback)
    return { direction: "to right", colors: [gradientString] };
  };

  const setCanvasBackground = (canvas, background) => {
    if (background.startsWith("linear-gradient")) {
      const { direction, colors } = templateparseGradient(background);

      let x1 = 0,
        y1 = 0,
        x2 = 0,
        y2 = 0;
      if (direction.includes("to right")) {
        x2 = canvas.width;
      } else if (direction.includes("to bottom")) {
        y2 = canvas.height;
      } else if (direction.includes("to left")) {
        x1 = canvas.width;
      } else if (direction.includes("to top")) {
        y1 = canvas.height;
      }

      const gradient = new fabric.Gradient({
        type: "linear",
        gradientUnits: "pixels",
        coords: { x1, y1, x2, y2 },
        colorStops: colors.map((color, index) => ({
          offset: index / (colors.length - 1),
          color,
        })),
      });

      canvas.setBackgroundColor(gradient, canvas.renderAll.bind(canvas));
    } else {
      canvas.setBackgroundColor(background, canvas.renderAll.bind(canvas));
    }
  };
  useEffect(() => {}, [rangeValue]);
  const createCanvasnew = (logoData) => {
    return new Promise((resolve) => {
      if (!logoData) return;

      let canvasWidth;
      let canvasHeight;
      let canvasOrginalWidth;
      let canvasOrginalHeight;
      let background = "#000";
      canvasWidth = rangeValue;
      canvasHeight = rangeValue;
      background = logoData.background;
      canvasOrginalWidth = logoData.width;
      canvasOrginalHeight = logoData.height;
      canvas_temp_download = new fabric.Canvas(refdonwload, {
        width: canvasWidth,
        height: canvasHeight,
        borderColor: "#000",
        borderWidth: 2,
      });

      setCanvasBackground(canvas_temp_download, background);

      const scaleFactorWidth = canvasWidth / canvasOrginalWidth;
      const scaleFactorHeight = canvasHeight / canvasOrginalHeight;
      const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

      const renderNextObject = async (index) => {
        if (index >= logoData.objects.length) {
          resolve(canvas_temp_download); // Resolve the promise when rendering is complete
          return;
        }
        const objData = logoData.objects[index];
        if (objData.type === "i-text" && objData.metadata) {
          const customWidth = objData.customWidth || objData.width;
          objData.customWidth = customWidth;
        }

        const scaledObject = {
          ...objData,
          scaleX: (objData.scaleX || 1) * scaleFactor,
          scaleY: (objData.scaleY || 1) * scaleFactor,
          left: (objData.left || 0) * scaleFactor,
          top: (objData.top || 0) * scaleFactor,
        };

        fabric.util.enlivenObjects([scaledObject], (objects) => {
          objects.forEach((obj) => {
            obj.selectable = false;
            if (obj.type === "i-text") {
              const customWidth = obj.customWidth || obj.width;
              obj.set({ textAlign: obj.textAlign });
              obj.set({
                width: customWidth,
              });
            }
            canvas_temp_download.add(obj);
          });
          canvas_temp_download.requestRenderAll();
          renderNextObject(index + 1);
        });
      };

      renderNextObject(0);
    });
  };

  const createCanvas = async (
    ref,
    logoData,
    index_act,
    width_height,
    allResponses,
    different_color_shade,
    change_data,
    color_index,
    PriorityWiseColor,
    change_icon_id,
    isshapbcg,
    onsave = 0
  ) => {
    let bckgrndclr;
    let combinationarray;
    let PriorityWiseColorArray;
    //console.log(change_data);

    if (different_color_shade && change_data == "yes") {
      PriorityWiseColorArray = PriorityWiseColor.split(",");
      const different_color_shadearry = JSON.parse(different_color_shade);
      ///PriorityWiseColorArray[color_index]
      //console.log(different_color_shadearry[PriorityWiseColorArray[color_index]]);
      if (
        different_color_shadearry[PriorityWiseColorArray[color_index]] != "same"
      ) {
        combinationarray =
          different_color_shadearry[PriorityWiseColorArray[color_index]];
      }

      bckgrndclr = combinationarray.background;
    }
    // Check if card_colors exists and has a string value
    if (!retrieveData("card_colors")) {
      return null; // Return null or handle appropriately
    }

    const templatejson = logoData;
    console.log(colWidth);
    let newCanvasWidth = colWidth; // New canvas width
    let newCanvasHeight = colWidth; // New canvas height

    if (window.innerWidth < 350) {
      document.querySelectorAll(".adjustwidthcls").forEach(function (element) {
        element.style.left = "35px";
      });
      newCanvasHeight = colWidth + 20;
    } else if (window.innerWidth < 380) {
      document.querySelectorAll(".adjustwidthcls").forEach(function (element) {
        element.style.left = "75px";
      });
      newCanvasHeight = colWidth + 20;
    } else if (window.innerWidth < 450) {
      document.querySelectorAll(".adjustwidthcls").forEach(function (element) {
        element.style.left = "50px";
      });
      newCanvasHeight = colWidth + 20;
    } else if (window.innerWidth <= 500) {
      document.querySelectorAll(".adjustwidthcls").forEach(function (element) {
        element.style.left = "30px";
      });
      newCanvasHeight = colWidth + 20;
    } else if (window.innerWidth <= 530) {
      document.querySelectorAll(".adjustwidthcls").forEach(function (element) {
        element.style.left = "20px";
      });
      newCanvasHeight = colWidth + 20;
    } else if (window.innerWidth < 576) {
      document.querySelectorAll(".adjustwidthcls").forEach(function (element) {
        element.style.left = "10px";
      });
      newCanvasHeight = colWidth + 20;
    } else {
      document.querySelectorAll(".adjustwidthcls").forEach(function (element) {
        element.style.left = "0px";
      });
    }

    // let newCanvasWidth;
    // let newCanvasHeight;

    // if (onsave == 0) {
    //   newCanvasWidth = 238; // New canvas width by mk
    //   newCanvasHeight = 300; // New canvas height by mk
    // } else {
    //   //save time
    //   newCanvasWidth = 400; // New canvas width by mk
    //   newCanvasHeight = 400; // New canvas height by mk
    // }

    // Calculate scale factor based on the new canvas size
    const originalWidth = 400;
    const originalHeight = 400;
    const scaleFactor = Math.min(
      newCanvasWidth / originalWidth,
      newCanvasHeight / originalHeight
    );

    const palettes = generateColorPalettes();

    // Choose the palette based on index
    const selectedPalette = palettes[index_act % palettes.length];
    // const backgroundHex = selectedPalette.background;
    // const textHex = selectedPalette.text;
    // const elementHex = selectedPalette.element;
    const canvas = new fabric.Canvas(ref, {
      width: newCanvasWidth,
      height: newCanvasHeight,
    });

    if (!combinationarray) {
      // Set background color or gradient
      if (
        templatejson.background &&
        templatejson.background.startsWith("linear-gradient")
      ) {
        const { direction, colors } = parseGradient(templatejson.background);

        let x1 = 0,
          y1 = 0,
          x2 = newCanvasWidth,
          y2 = newCanvasHeight;
        if (direction.includes("to right")) {
          x2 = canvas.width;
        } else if (direction.includes("to bottom")) {
          y2 = canvas.height;
        } else if (direction.includes("to left")) {
          x1 = canvas.width;
        } else if (direction.includes("to top")) {
          y1 = canvas.height;
        }

        const gradient = new fabric.Gradient({
          type: "linear",
          gradientUnits: "pixels",
          coords: { x1, y1, x2, y2 },
          colorStops: colors.map((color, index) => ({
            offset: index / (colors.length - 1),
            color,
          })),
        });
        canvas.backgroundColor = gradient;
        //canvas.backgroundColor = templatejson.background;
      } else {
        //canvas.backgroundColor = backgroundHex; // Fallback to palette color if no background is set
        canvas.backgroundColor = templatejson.background;
      }
    } else {
      canvas.backgroundColor = bckgrndclr;
    }

    if (!Array.isArray(templtarray[index_act])) {
      templtarray[index_act] = []; // Initialize it as an array if it doesn't exist
      backgrondclr[index_act] = [];
    } else {
      templtarray[index_act].length = 0; //blank the array
      backgrondclr[index_act].length = 0;
    }

    /********** Text code Start ************/
    const newCompanyText = companyName;
    const newSloganText = sloganName;
    if (templatejson.objects) {
      //templatejson.objects.forEach(async (objData) => {

      const renderNextObject = async (index) => {
        if (index >= logoData.objects.length) {
          //resolve();
          return;
        }
        const objData = templatejson.objects[index];
        let newcolor_code = "";

        if (objData.type === "image" && objData.metadata) {
          if (objData.metadata.role === "Shapes") {
            if (objData.MkIconId == isshapbcg) {
              let svgDataUrl = objData.src;
              if (!svgDataUrl) {
                svgDataUrl = objData.getSrc();
              }
              newcolor_code = bckgrndclr; // Ensure colordata and icon are properly defined

              if (newcolor_code) {
                await fetch(svgDataUrl)
                  .then((response) => response.text())
                  .then((svgData) => {
                    const fillColor = getFillColorFromSVG(svgData); // Ensure this function is defined
                    const modifiedSvgData = replaceColorInSVG(
                      svgData,
                      fillColor,
                      bckgrndclr
                    ); // Ensure this function is defined

                    objData.src =
                      "data:image/svg+xml;base64," + btoa(modifiedSvgData);
                  })
                  .catch((error) => {
                    console.error("Error fetching SVG data:", error);
                  });
              }
            }
          }

          if (objData.metadata.role === "icon") {
            //console.log(change_icon_id);
            //console.log(iconselect);
            let svgDataUrl = "";
            if (iconselect && objData.MkIconId == change_icon_id) {
              //console.log("come inside this");
              const randomIndex = Math.floor(Math.random() * iconselect.length);
              svgDataUrl = iconselect[randomIndex];
              if (combinationarray) {
                newcolor_code = combinationarray.icon;
              }
            } else {
              svgDataUrl = objData.src; //console.log(svgDataUrl);
              //console.log(objData.MkIconId,change_icon_id);
              if (combinationarray) {
                newcolor_code = combinationarray.icon;
              }
            }

            await fetch(svgDataUrl)
              .then((response) => response.text())
              .then((svgData) => {
                if (combinationarray) {
                  const fillColor = getFillColorFromSVG(svgData); // Ensure this function is defined
                  //const fillColor = '#000000'; // Ensure this function is defined
                  //console.log(fillColor);
                  const modifiedSvgData = replaceColorInSVG(
                    svgData,
                    fillColor,
                    newcolor_code
                  ); // Ensure this function is defined

                  objData.src =
                    "data:image/svg+xml;base64," + btoa(modifiedSvgData);
                } else {
                  objData.src = svgDataUrl;
                }
                canvas.renderAll();
              })
              .catch((error) => {
                console.error("Error fetching SVG data:", error);
              });
          }
        }

        const scaledObject = {
          ...objData,
          scaleX: (objData.scaleX || 1) * scaleFactor,
          scaleY: (objData.scaleY || 1) * scaleFactor,
          left: (objData.left || 0) * scaleFactor,
          top: (objData.top || 0) * scaleFactor,
        };

        fabric.util.enlivenObjects([scaledObject], (objects) => {
          objects.forEach((obj, lop) => {
            obj.selectable = false;
            if (obj.type === "i-text" && obj.metadata) {
              let newText = "";
              if (obj.metadata.role === "company_name") {
                newText = newCompanyText;
                if (combinationarray) {
                  obj.fill = combinationarray.companyText; // Direct property assignment
                }
              } else if (obj.metadata.role === "slogan") {
                newText = newSloganText;
                if (combinationarray) {
                  obj.fill = combinationarray.tagline; // Direct property assignment
                }
              } else if (obj.metadata.role === "Letter_first") {
                const words = newCompanyText.trim().split(/\s+/); // Split by one or more spaces
                newText = words[0] ? words[0].charAt(0) : "";
              } else if (obj.metadata.role === "Letter_Second") {
                const words = newCompanyText.trim().split(/\s+/); // Split by one or more spaces
                newText = words[1] ? words[1].charAt(0) : "";
              } else if (obj.metadata.role === "Letter_Third") {
                const words = newCompanyText.trim().split(/\s+/); // Split by one or more spaces
                newText = words[2] ? words[2].charAt(0) : "";
              }

              const customWidth = obj.customWidth || obj.width;
              obj.set("text", newText);

              let textWidth = obj.width,
                textHeight = obj.height,
                maxWidth = customWidth, // set the maximum width
                maxHeight = 100, // set the maximum height
                fontSize = obj.fontSize;

              // Calculate the maximum font size that fits the text within the textbox
              while (textWidth > maxWidth || textHeight > maxHeight) {
                fontSize--;
                obj.set("fontSize", fontSize);
                //console.log(fontSize);
                textWidth = obj.width;
                textHeight = obj.height;
              }

              obj.set({
                textAlign: obj.textAlign,
              });

              obj.set({
                width: customWidth,
              });
            }
            canvas.add(obj);
          });
          canvas.requestRenderAll();
          if (onsave != 0) {
            templtarray[index_act].push(objects[0]);
            backgrondclr[index_act].push(canvas.backgroundColor);
          }
          renderNextObject(index + 1);
        });

        //});
      };

      renderNextObject(0);
    }
    //console.log(canvas);
    canvas.requestRenderAll();
    ///return canvas;
    /********** Text code close ************/
  };

  const hexToHSL = (hex) => {
    // To convert Hex to HSL
    let r = 0,
      g = 0,
      b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;
    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
  };

  const hslToHex = (h, s, l) => {
    // Convert HSL to HEX
    // Convert HSL to RGB first
    let r, g, b;
    if (s === 0) {
      r = g = b = l; // Achromatic (grey)
    } else {
      const hueToRgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
    }

    // Convert RGB to HEX
    const toHex = (x) => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    const hexColor = `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    return hexColor.toUpperCase();
  };

  const colorNameToHex = (colorName, customColors) => {
    switch (
      colorName.toLowerCase().replace(/["[\]]/g, "") // Remove unwanted characters
    ) {
      case "red":
        return "#FF0000";
      case "yellow":
        return "#FFFF00";
      case "blue":
        return "#0000FF";
      case "black":
        return "#000000";
      case "pink":
        return "#FFC0CB";
      case "purple":
        return "#800080";
      case "teal":
        return "#008080";
      case "green":
        return "#008000";
      case "orange":
        return "#FFA500";

      default:
        return customColors[colorName.toLowerCase()] || "";
    }
  };

  const generateColorPalettes = () => {
    const baseColorNames = retrieveData("card_colors") || [];

    // Filter and clean color names
    const cleanedBaseColorNames = baseColorNames.map((name) =>
      name.replace(/["[\]]/g, "")
    );

    // Convert color names to HEX format
    const baseHexColors = cleanedBaseColorNames.map(colorNameToHex);
    // Convert HEX colors to HSL format
    const baseHSLColors = baseHexColors.map(hexToHSL);

    const palettes = [];
    const adjustLightness = (lightness, amount) =>
      Math.min(Math.max(lightness + amount, 0), 100);
    const adjustSaturation = (saturation, amount) =>
      Math.min(Math.max(saturation + amount, 0), 100);

    for (let i = 0; i < 50; i++) {
      const colorIndex = i % baseHSLColors.length; // Alternate base colors
      const [hue, baseSaturation, baseLightness] = baseHSLColors[colorIndex];

      const backgroundLightness = adjustLightness(
        baseLightness,
        random(-40, 40)
      );
      const textLightness = adjustLightness(
        backgroundLightness,
        backgroundLightness > 50 ? -random(40, 60) : random(40, 60)
      );
      const elementLightness = adjustLightness(
        backgroundLightness,
        backgroundLightness > 50 ? -random(20, 30) : random(20, 30)
      );

      // Convert HSL to HEX
      const backgroundHex = hslToHex(
        hue / 360,
        adjustSaturation(baseSaturation, random(-20, 20)) / 100,
        backgroundLightness / 100
      );
      const textHex = hslToHex(
        hue / 360,
        adjustSaturation(baseSaturation, random(-20, 20)) / 100,
        textLightness / 100
      );
      const elementHex = hslToHex(
        hue / 360,
        adjustSaturation(baseSaturation, random(-20, 20)) / 100,
        elementLightness / 100
      );

      palettes.push({
        n: i + 1,
        background: backgroundHex,
        text: textHex,
        element: elementHex,
      });
    }
    return palettes;
  };
  const [selectedSVGs, setSelectedSVGs] = useState([]);
  const [selectedIconId, setselectedIconId] = useState([]);
  useEffect(() => {
    // Retrieve selected icons from local storage on component mount
    const selectedIconsFromStorage =
      JSON.parse(retrieveData("selectedIcons")) || [];
    setSelectedSVGs(selectedIconsFromStorage);
    retrieveIconId(selectedIconsFromStorage);
    // Retrieve data from local storage
  }, []);

  // useEffect(() => {
  //   const flag = "1";
  //   const call_id = "1";
  //   master_data_get(flag, call_id);
  // }, []);

  const retrieveIconId = (array) => {
    // Map over the array to extract ids
    const newArray = array.map((icon) => icon.id);
    setselectedIconId(newArray);
    master_data_get_icons(newArray);
  };

  const handleDelete = (event, index) => {
    setIsChanged(true);
    event.preventDefault();
    const updatedSVGs = [...selectedSVGs];

    updatedSVGs.splice(index, 1);

    setSelectedSVGs(updatedSVGs);
    storeData("selectedIcons", JSON.stringify(updatedSVGs));
  };

  //dropdown 1 starts

  //dropdown2 starts
  const [searchTerm, setSearchTerm] = useState(retrieveData("query"));
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const [isActive, setIsActive] = useState(false);

  const searchIcons = async (click_type = 0, search_data = "", offset = 0) => {
    setIsChanged(true);
    const formData = new FormData();
    formData.append("query", search_data || searchTerm);
    formData.append("companyName", companyName);
    formData.append("limit_to_public_domain", 1);
    formData.append("thumbnail_size", 30);
    formData.append("limit", 30); // Load 30 icons each time
    formData.append("offset", offset); // Offset for pagination
    formData.append("click_type", click_type);
    formData.append("include_svg", 1);

    try {
      const response = await server_post_data(get_icon_img, formData);
      const newIcons = response.data.message.icondata.icons;

      if (Array.isArray(newIcons)) {
        if (newIcons.length === 0) {
          setHasMore(false); // No more icons to load
        } else {
          SetIconImg((prevIcons) => [...prevIcons, ...newIcons]); // Append new icons
          setOffset((prevOffset) => prevOffset + 30); // Increase offset
        }
      }
    } catch (error) {
      handleError("network");
    }
  };
  setTimeout(() => {
    if (iincment2 == 0) {
      searchIcons();
      iincment2++;
    }
  }, 2000);

  const loadMoreRef = useRef(null);

  const loadMoreIcons = async () => {
    console.log("clicked ");
    if (!hasMore || loadingMore) return;

    setLoadingMore(true);
    setshowLoaderAdmin(true);

    try {
      await searchIcons(0, "", offset);
    } catch (error) {
      handleError("network");
    } finally {
      setLoadingMore(false);
      setshowLoaderAdmin(false);
    }
  };

  const handleVisibility = (entries) => {
    if (entries[0].isIntersecting && !loadingMore) {
      loadMoreIcons();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleVisibility, {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 1.0,
    });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [loadMoreRef.current, loadingMore]);

  const handleButtonClick = (index) => {
    setIsChanged(true);
    setIsActive(index);
    setSearchTerm(iconsRelated[index].label);
    searchIcons(1, iconsRelated[index].label);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchIcons(0, searchTerm);
    }
  };

  //-------------------otp function end

  // State to hold the company name
  const [companyName, setCompanyName] = useState("");

  const [sloganName, setSloganName] = useState("");

  // useEffect to fetch the company name from local storage when the component mounts
  useEffect(() => {
    let storedCompanyName = retrieveData("companyName");
    if (storedCompanyName) {
      // Remove surrounding quotes if they exist
      if (
        storedCompanyName.startsWith('"') &&
        storedCompanyName.endsWith('"')
      ) {
        storedCompanyName = storedCompanyName.slice(1, -1);
      }
      setCompanyName(storedCompanyName);
    }
    let storedSloganName = retrieveData("slogan");
    if (storedSloganName) {
      // Remove surrounding quotes if they exist
      if (storedSloganName.startsWith('"') && storedSloganName.endsWith('"')) {
        storedSloganName = storedSloganName.slice(1, -1);
      }
      setSloganName(storedSloganName);
    }
  }, []);

  // Function to handle input changes
  const handleInputChange = (e) => {
    setCompanyName(e.target.value);
    storeData("companyName", e.target.value);
    setIsChanged(true);
  };

  // Function to handle input changes
  const handleInputChangeForSlogan = (e) => {
    setSloganName(e.target.value);
    storeData("slogan", e.target.value);
    setIsChanged(true);
  };
  const handleClick = (event, index) => {
    event.preventDefault();
    const selectedSVG = GetIcon[index];
    const selectedIndex = selectedSVGs.findIndex(
      (svg) => svg.thumbnail_url === selectedSVG.thumbnail_url
    );

    if (selectedIndex === -1) {
      if (selectedSVGs.length < 5) {
        let svgDataUrl = selectedSVG.icon_url;

        // Ensure the URL is valid
        if (svgDataUrl) {
          fetch(svgDataUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.text();
            })
            .then((svgData) => {
              const base64Svg = btoa(unescape(encodeURIComponent(svgData)));
              const dataUrl = "data:image/svg+xml;base64," + base64Svg;
              //iconselect.push(dataUrl);
              iconselect2 = dataUrl;

              const updatedSelectedSVGs = [
                ...selectedSVGs,
                {
                  id: selectedSVG.id,
                  thumbnail_url: selectedSVG.thumbnail_url,
                  icon_url: selectedSVG.icon_url,
                  iconselect: iconselect2,
                },
              ];
              console.log(updatedSelectedSVGs);
              setSelectedSVGs(updatedSelectedSVGs);
              storeData("selectedIcons", JSON.stringify(updatedSelectedSVGs));
            })
            .catch((error) => {
              console.error("Failed to fetch SVG:", error);
            });
        } else {
          iconselect2 = "";
          console.warn("No valid URL found for SVG data");
        }
      }
    } else {
      const updatedSelectedSVGs = [...selectedSVGs];
      updatedSelectedSVGs.splice(selectedIndex, 1);
      setSelectedSVGs(updatedSelectedSVGs);
      storeData("selectedIcons", JSON.stringify(updatedSelectedSVGs));
    }

    if (selectedSVGs.length >= 5) {
      setIsActive(null);
    }
  };

  const [likedHeardLoader, setLikedHeardLoader] = useState(false);
  const [lastInsertedIds, setLastInsertedIds] = useState({});
  const [likedLogos, setLikedLogos] = useState(
    new Array(logos.length).fill(false)
  );
  const [showModal, setShowModal] = useState(false);

  const [navigateTo, setNavigateTo] = useState(""); // Track the navigation URL

  const handleLikeClick = (index, primary_id) => {
    const isLiked = likedLogos[index];

    if (!user_id) {
      //console.log("User not logged in. Dispatching custom event.");
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
      return;
    }

    // Show loader for this specific template
    setLikedHeardLoader((prev) => ({ ...prev, [index]: true }));
    const updatedLikedLogos2 = [...likedLogos];
    if (updatedLikedLogos2[index] == false || !updatedLikedLogos2[index]) {
      //setTimeout(async () => {
      handleEditClick(isLiked, index, primary_id, 0)
        .then((last_inserted_id) => {
          //console.log("Last Inserted ID:", last_inserted_id);
          const updatedLikedLogos = [...likedLogos];
          updatedLikedLogos[index] = true;
          setLikedLogos(updatedLikedLogos);
          WishlistTemp++;
          setLikedHeardLoader((prev) => ({ ...prev, [index]: false }));
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      // }, 1000);
    }

    if (likedLogos[index]) {
      //console.log(lastInsertedIds);
      //console.log(lastInsertedIds[index]);
      setShowModal(true);
      currentindex = index;
    }
  };
  const handleConfirmUnclick = () => {
    setShowModal(false);
    //setTimeout(async () => {

    let fd_from = new FormData();
    console.log(lastInsertedIds[currentindex]);
    fd_from.append("primary_id", lastInsertedIds[currentindex]);
    server_post_data(delete_user_templates, fd_from)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          const updatedLikedLogos = [...likedLogos];
          updatedLikedLogos[currentindex] = false;
          setLikedLogos(updatedLikedLogos);
          WishlistTemp--;
          setLikedHeardLoader((prev) => ({ ...prev, [currentindex]: false }));
        }
      })
      .catch((error) => {
        handleError("network");
      });

    //}, 1000);
  };

  const handleCancelUnclick = () => {
    console.log(WishlistTemp);
    setLikedHeardLoader((prev) => ({ ...prev, [currentindex]: false }));
    setShowModal(false);
  };

  const convertCanvasToJPG = (index) => {
    return new Promise((resolve, reject) => {
      try {
        // Convert the canvas to a JPG data URL
        const canvas = canvasRefs.current[index];
        const jpgDataURL = canvas.toDataURL("image/jpeg", 1.0);
        resolve(jpgDataURL);
      } catch (error) {
        reject(error);
      }
    });
  };
  const [isVisible, setIsVisible] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("");

  useEffect(() => {
    if (navigateTo) {
      console.log("Navigating to:", navigateTo);
      navigate(navigateTo);
      // Reset navigation state after navigating
      setNavigateTo("");
    } else {
      console.log("No navigation URL set.");
    }
  }, [navigateTo]);

  const base64ToBlob = (base64, mime) => {
    // Check if base64 string is correct
    if (!base64 || !mime) {
      throw new Error("Invalid Base64 string or MIME type");
    }

    // Handle Data URL format
    const byteString = atob(base64.split(",")[1]);
    const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];

    // Create a buffer and Uint8Array to handle byte data
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mime || mimeString });
  };

  //edit button function

  //edit button function
  const handleEditClick = async (
    logoData,
    index_act,
    primary_id,
    nevigatn = 1
  ) => {
    //console.log("yes click");
    //console.log(nevigatn);
    //let canvasData;

    // canvasData = await handleCanvasRef(
    //   '',
    //   logoData,
    //   'mak',
    //   '',
    //   '',
    //   logoData.different_color_shade,
    //   0,
    // );
    // canvasData = await createCanvas(
    //   '',
    //   JSON.parse(logoData.template_canvas),
    //   'mak',
    //   '',
    //   '',
    //   logoData.different_color_shade,
    //   logoData.change_data,
    //   logoData.color_index,
    //   logoData.PriorityWiseColor,
    //   logoData.change_icon_id,
    //   1,
    // );
    //let indev = index_act + "_111";
    let indev = index_act;
    //console.log((indev));
    //console.log((templtarray[indev]));
    //return
    const data = templtarray[indev].map((obj) => {
      let objData = obj.toObject();
      objData.flag = 0; // Append flag 0
      objData.color = obj.fill || "";
      objData.customWidth = obj.customWidth || obj.width;

      // Add mergedFonts to each object and include font source
      return objData;
    });
    //console.log(data);
    // console.log(backgrondclr[indev]);
    //return;
    const canvasData = {
      objects: data,
      background: backgrondclr[indev][0],
      width: colWidth,
      height: colWidth,
    };

    //return;
    const user_id = retrieveData("user_id");
    if (!user_id) {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    } else {
      if (canvasData.objects.length > 0) {
        let fd_from = new FormData();
        fd_from.append("customer_id", user_id);
        fd_from.append("main_id", "0");
        fd_from.append("primary_id", primary_id);

        //console.log(canvasData);
        //return;
        fd_from.append("template_canvas_data", JSON.stringify(canvasData));
        const canvasImageURL = await convertCanvasToJPG(index_act);
        const blob = base64ToBlob(canvasImageURL, "image/jpeg");
        fd_from.append("file", blob, "canvas-image.jpg");
        console.log(blob);
        //setshowLoaderAdmin(true);
        try {
          const Response = await axios.post(
            save_update_user_templates,
            fd_from,
            {
              headers: {
                "Content-Type": "multipart/form-data", // Set the Content-Type header for form data
              },
            }
          );
          if (Response.status === 200) {
            //await server_post_data(save_update_user_templates, fd_from).then((Response) => {
            if (Response.data.error) {
              handleError(Response.data.message);
            } else {
              //handleSuccess(Response.data.message);
              if (nevigatn === 1) {
                // Delay navigation by 2 seconds
                setTimeout(() => {
                  setIsLoaderVisible(false);
                  setNavigateTo(
                    `/canvasEdit/logo/${Response.data.last_inserted_id}`
                  );
                }, 2000);
              }
              if (nevigatn == 2) {
                storeData("last_inserted_id", Response.data.last_inserted_id);
                setNavigateTo(`/pricing`);
                //storeData("last_inserted_id", Response.data.last_inserted_id);

                // Retrieve the active_package from local storage
                // const activePackage = localStorage.getItem("active_package");

                // if (activePackage === null) {
                //   setNavigateTo(`/pricing`);
                // } else {
                //   console.log(
                //     "Active package exists, not navigating to pricing."
                //   );
                // }
              } else {
                //wishlist logic

                setLastInsertedIds((prev) => ({
                  ...prev,
                  [index_act]: Response.data.last_inserted_id, // Store the last_inserted_id for this index
                }));
                //console.log(lastInsertedIds);
                return Response.data.last_inserted_id;
              }
            }
          }
        } catch (error) {
          handleError("network");
        }
        //setshowLoaderAdmin(false);
        // })
        // .catch((error) => {
        //   //setshowLoaderAdmin(false);
        //   handleError("network");
        // });
      } else {
        handleError("Please Add Creative");
      }
    }
  };

  const handleEditSaveCanvas = (logoData, index) => {
    setshowLoaderAdmin(true);
    setIsLoaderVisible(true);
    handleEditClick(logoData, index);
  };

  //download the template

  const handleFormatChange = (event) => {
    const selectedFormat = event.target.value;
    setSelectedFormat(selectedFormat);

    if (selectedFormat === "png") {
      setshowImgSizeRange(true);
    } else {
      setshowImgSizeRange(false);
    }
  };
  const handleDownload = () => {
    downloadCanvasTemplate(selectedFormat);
  };

  const handleRangeChange = (event) => {
    const newValue = Number(event.target.value);
    setRangeValue(newValue);
  };

  const handleDownloadFormetMsg = () => {
    console.log("handleDownloadFormetMsgdadadad");
    setModalMessage(true);
  };
  const handleDownloadModlCLose = () => {
    setModalMessage(false);
  };

  const downloadCanvasTemplate = async () => {
    const data = templtarray[DownloadCanvasindex].map((obj) => {
      let objData = obj.toObject();
      objData.flag = 0; // Append flag 0
      objData.color = obj.fill || "";
      objData.customWidth = obj.customWidth || obj.width;

      // Add mergedFonts to each object and include font source
      return objData;
    });

    const canvasData = {
      objects: data,
      background: backgrondclr[DownloadCanvasindex][0],
      width: colWidth,
      height: colWidth,
    };

    await createCanvasnew(canvasData).then((canvasnew) => {
      newcanvas = canvasnew;

      switch (selectedFormat) {
        case "png":
          downloadAsPNG();
          break;
        case "jpg":
          //downloadAsJPG();
          break;
        case "svg":
          const vectorFile = localStorage.getItem("vector_file");
          if (vectorFile === "Vectors File") {
            downloadAsSVG();
          } else {
            navigate("/pricing");
          }
          break;
        case "pdf":
          downloadAsPDF();
          break;
        default:
          handleDownloadFormetMsg();
      }
    });
  };

  const replaceCanvasBackgroundWithTransparent = (canvas) => {
    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = canvas.width;
    tempCanvas.height = canvas.height;
    const tempCtx = tempCanvas.getContext("2d");
    tempCtx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
    tempCtx.drawImage(canvas, 0, 0);

    return tempCanvas;
  };

  let transparent_background = transparentBackground ? 1 : 0; // Set this dynamically as needed

  const downloadAsPNG = () => {
    const origBackgroundColor = newcanvas.backgroundColor;
    const origWatermarkUrl = "";
    const origWatermarkVisible = newcanvas.backgroundImage?.visible;

    // Temporarily make the watermark invisible
    if (newcanvas.backgroundImage) {
      newcanvas.backgroundImage.visible = false;
      newcanvas.renderAll();
    }

    // Set the background color to transparent if needed
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      const dataURL = newcanvas.toDataURL({
        format: "png",
        quality: 1.0,
        background: "transparent",
      });
      downloadAndSaveImage(dataURL, "image.png");
      newcanvas.setBackgroundColor(origBackgroundColor, () => {
        newcanvas.renderAll();
        if (newcanvas.backgroundImage) {
          newcanvas.backgroundImage.visible = origWatermarkVisible;
          newcanvas.renderAll();
        }
      });
    });
  };

  // const downloadAsJPG = () => {
  //   const origBackgroundColor = mainCanvas.backgroundColor;
  //   const backgroundColor = transparent_background === 1 ? "transparent" : origBackgroundColor;

  //   // Temporarily change the background color if needed
  //   mainCanvas.setBackgroundColor(backgroundColor, () => {
  //     mainCanvas.renderAll();
  //     const dataURL = mainCanvas.toDataURL({
  //       format: "jpeg",
  //       quality: 1.0,
  //     });
  //     downloadAndSaveImage(dataURL, "canvas.jpg");

  //     // Reset background color
  //     mainCanvas.setBackgroundColor(origBackgroundColor, () => {
  //       mainCanvas.renderAll();
  //     });
  //   });
  // };

  const downloadAsSVG = () => {
    const origBackgroundColor = newcanvas.backgroundColor;
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    // Set background color to transparent if needed
    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      if (newcanvas) {
        const svg = newcanvas.toSVG({
          reviver: (obj, instance) => {
            if (instance instanceof fabric.Object) {
              return instance.toSVG();
            }
            return obj;
          },
        });
        const blob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "canvas.svg";
        link.click();

        // Clean up
        URL.revokeObjectURL(url);

        // Reset background color
        newcanvas.setBackgroundColor(origBackgroundColor, () => {
          newcanvas.renderAll();
        });
      } else {
        console.error("mainCanvas is undefined, unable to download SVG");
      }
    });
  };

  const downloadAsPDF = () => {
    if (!newcanvas) return;

    const origBackgroundColor = newcanvas.backgroundColor;
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    // Set background color to transparent if needed
    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      const canvasElement = newcanvas;
      const dataURL = canvasElement.toDataURL("image/png");

      const pdf = new jsPDF();
      pdf.addImage(dataURL, "PNG", 0, 0);
      pdf.save("canvas.pdf");

      // Reset background color
      newcanvas.setBackgroundColor(origBackgroundColor, () => {
        newcanvas.renderAll();
      });
    });
  };

  const downloadAndSaveImage = (dataURL, filename) => {
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = filename;
    link.click();
  };
  const [DownloadCanvas, setDownloadCanvas] = useState(null);
  const [DownloadCanvasindex, setDownloadCanvasindex] = useState(-1);
  const toggleVisibility = (indev, primary_id, logodata) => {
    if (vectorFile !== "Vectors File") {
      handleEditClick(logodata, indev, primary_id, 2);
      //navigate("/pricing");
      return;
    } else {
      console.log("clicked");
      setIsVisible(!isVisible);
      setDownloadCanvas(logodata);
      setDownloadCanvasindex(indev);
    }
  };

  const handlesectionDisable = () => {
    setIsVisible(!isVisible);
  };

  const handleCanvasRef = (
    ref,
    logoData,
    index,
    colWidth,
    allResponses,
    different_color_shade,
    intrvl
  ) => {
    if (ref && !canvasRefs.current[index]) {
      // Set initial loader state
      setLoadingStates((prev) => {
        const newStates = [...prev];
        newStates[index] = true;
        return newStates;
      });

      canvasRefs.current[index] = ref;

      intrvl = 0;
      setTimeout(() => {
        try {
          createCanvas(
            ref,
            JSON.parse(logoData.template_canvas),
            index,
            colWidth,
            allResponses,
            different_color_shade,
            logoData.change_data,
            logoData.color_index,
            logoData.PriorityWiseColor,
            logoData.change_icon_id,
            logoData.change_background_shape_id,
            "1"
          );

          // Keep loader for an extra 1 second after canvas has finished loading
          setTimeout(() => {
            setLoadingStates((prev) => {
              const newStates = [...prev];
              newStates[index] = false;
              return newStates;
            });
          }, 1200); // 1 second delay after the canvas is loaded
        } catch (error) {
          console.error("Error creating canvas:", error);
        }
      }, 0);
    }
  };

  const handleRefreshClick = () => {
    console.log("Refresh Button");

    // Reset startIndexRef and endIndexRef
    startIndexRef.current = 0;
    endIndexRef.current = 15;

    // Fetch card_colors from localStorage
    const cardColorsStr = localStorage.getItem("card_colors");

    // If cardColorsStr is null or not an array, handle accordingly
    let cardColorsArray = [];
    if (cardColorsStr) {
      try {
        cardColorsArray = JSON.parse(cardColorsStr) || [];
      } catch (error) {
        console.error("Error parsing card_colors from localStorage:", error);
        cardColorsArray = [];
      }
    }

    // Convert array to a comma-separated string
    const cardColors = cardColorsArray.join(",");

    console.log("card_colors:", cardColors);

    // Call master_data_get with updated parameters
    master_data_get(selectedIndustry, cardColors, "0", "0");

    // Reset the state if necessary
    setIsChanged(false);
  };

  const handleCanvasRef2 = (
    ref,
    logoData,
    index,
    colWidth,
    allResponses,
    different_color_shade,
    intrvl
  ) => {
    if (ref && !canvasRefs.current[index + "_111"]) {
      // createCanvas(
      //   ref,
      //   JSON.parse(logoData.template_canvas),
      //   index + "_111",
      //   colWidth,
      //   allResponses,
      //   different_color_shade,
      //   logoData.change_data,
      //   logoData.color_index,
      //   logoData.PriorityWiseColor,
      //   logoData.change_icon_id,
      //   logoData.change_background_shape_id,
      //   "1"
      // );
      // //}, intrvl);
      // //setshowLoaderAdmin(false);
      // //console.log(logoData.preferred_color);
      // canvasRefs.current[index] = ref; // Store the reference to prevent re-creation
    } else {
      //console.log('already have');
    }
  };

  return (
    <div>
      <Header
        Showheader={false}
        CheckLogin={false}
        wishlistTemp={WishlistTemp}
      />
      {isVisible && <div className="overLay"></div>}
      <div className="text-center my-3 mt-5">
        <h6>Create a stunning logo tailored just for you. Start for free!</h6>
      </div>
      <div
        className="colorTollCont h-100 w-100"
        style={{ position: "relative" }}
      >
        <div className=" form_wrapper">
          <form className="prefrences_form d-flex align-items-center">
            <div className="inputs_wrapper inputs_wrapper2">
              <input
                placeholder="Enter Company name"
                value={companyName}
                type="text"
                id="company-name"
                onChange={handleInputChange}
              />
              <input
                placeholder="Slogan (optional)"
                value={sloganName}
                type="text"
                id="slogan-name"
                onChange={handleInputChangeForSlogan}
              />
            </div>
            <div className="colors_holder_wrapper">
              {color && color.length > 0 && (
                <>
                  {color.map((item, index) => {
                    return (
                      <div key={index} className="squares">
                        <div
                          className="color_itemSelected w-100 h-100"
                          style={{
                            backgroundColor: item,
                          }}
                        >
                          <div className="colorGrade1"></div>
                          <div className="colorGrade2"></div>
                          <div className="colorGrade3"></div>
                          <div className="colorGrade4"></div>
                          <div className="colorGrade5"></div>
                          <div className="colorGrade6"></div>
                          <div className="colorGrade7"></div>
                          <div className="colorGrade8"></div>
                          <div className="colorGrade9"></div>
                        </div>
                        <button
                          onClick={(event) => handleDeleteColor(event, index)}
                        >
                          <img src={Delet} alt="Logo Academy" />
                        </button>
                      </div>
                    );
                  })}
                </>
              )}
            </div>

            <div className="plus_wrapper" style={{ position: "relative" }}>
              <img
                className={`plus ${rotatePlus1 ? "rotate" : ""}`}
                src={plus}
                ref={plusRef1}
                onClick={(event) => {
                  rotateDropdown1(event);
                }}
                alt="dropdown plus"
              />

              {showDropdown1 && (
                <div className="dropdown_menu3" ref={dropdownRef1}>
                  {color &&
                  color.filter((item) => item === "" || item === "").length ===
                    0 ? (
                    <div className="no_empty_slots_message">
                      <img src={topArrow} alt="Up Arrow"></img>
                      You have reached the maximum number of colors
                    </div>
                  ) : (
                    <div className="color_grid">
                      {colorItems.map((item, colorIndex) => (
                        <div
                          key={colorIndex}
                          className={`colorItem ${item.className} ${
                            color.includes(colorMapping[item.color])
                              ? "selected"
                              : ""
                          }`}
                          style={{
                            opacity: color.includes(colorMapping[item.color]),
                            position: "relative",
                          }}
                          onClick={() => handleColorItemClick(item.color)}
                        >
                          {color.includes(colorMapping[item.color]) && (
                            <div className="tickMark">
                              <img src={tickmark} alt="tick"></img>
                            </div>
                          )}
                          <div className="colorGrade1"></div>
                          <div className="colorGrade2"></div>
                          <div className="colorGrade3"></div>
                          <div className="colorGrade4"></div>
                          <div className="colorGrade5"></div>
                          <div className="colorGrade6"></div>
                          <div className="colorGrade7"></div>
                          <div className="colorGrade8"></div>
                          <div className="colorGrade9"></div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="placeHolders_wrapper placeHolders_wrapper_show_logos">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="logo_placeholder logo_placeholder2">
                  {index < selectedSVGs.length ? (
                    <img
                      src={selectedSVGs[index].thumbnail_url}
                      alt={`Selected SVG ${index + 1}`}
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <div className="empty_placeholder"></div>
                  )}
                  <button onClick={(event) => handleDelete(event, index)}>
                    <img src={Delet} alt="Logo Academy" />
                  </button>
                </div>
              ))}
            </div>
            <div className="plus_wrapper" style={{ position: "relative" }}>
              <img
                className={`plus ${rotatePlus2 ? "rotate" : ""}`}
                src={plus}
                ref={plusRef}
                onClick={(event) => {
                  rotateDropdown2(event);
                }}
                alt="plus icon"
              />
              {showDropdown2 && (
                <div className="dropdown_menu2" ref={dropdownRef2}>
                  <div className="dropdown2Container">
                    <div className="search_bar_container_dropdown2">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="search_input"
                        onKeyDown={handleKeyDown}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          searchIcons();
                        }}
                        className="search_button"
                      >
                        <img src={searchIcon} alt="searchimg" />
                      </button>
                    </div>
                    <div className="suggestionLinks_dropdown2">
                      {resultArray.map((suggestion, index) => (
                        <div key={index}>
                          <button
                            onClick={(event) => handleButtonClick(event, index)}
                            className={`suggestion_item ${
                              index === isActive ? "active" : ""
                            }`}
                          >
                            {suggestion.label}
                          </button>
                        </div>
                      ))}
                    </div>
                    <div
                      id="iconsContainer"
                      className="icons_dropdown2   "
                      style={{ justifyContent: "start" }}
                    >
                      {GetIcon.length > 0 ? (
                        GetIcon.map((svg, index) => (
                          <div
                            key={index}
                            className={`grid_item imgSHowBox ${
                              selectedSVGs.some(
                                (selectedSVG) =>
                                  selectedSVG.thumbnail_url ===
                                  svg.thumbnail_url
                              )
                                ? "clicked"
                                : selectedSVGs.length >= 5
                                ? "unselected"
                                : ""
                            }`}
                            onClick={(event) =>
                              selectedSVGs.length < 5
                                ? handleClick(event, index)
                                : null
                            }
                          >
                            <button>
                              <img
                                className=""
                                src={svg.thumbnail_url}
                                alt={`SVG ${index + 1}`}
                                onError={(e) =>
                                  (e.target.src = "default-image-url")
                                }
                              />
                            </button>
                          </div>
                        ))
                      ) : (
                        <div className="LoaderSearch lasticon">
                          <p>No data Found</p>
                        </div>
                      )}
                    </div>
                    <div className="moreDataLoader">
                      {" "}
                      {showLoaderAdmin && <LoderIcon />}
                    </div>

                    <div
                      className="mt-3 loadMoreBTns"
                      style={{ visibility: "hidden" }}
                    >
                      {hasMore && (
                        <button
                          ref={loadMoreRef}
                          id="loadMoreButton"
                          onClick={(event) => {
                            event.preventDefault();
                            loadMoreIcons();
                          }}
                        >
                          Load more
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <button
              id="refresh_btn"
              type="button"
              onClick={handleRefreshClick}
              style={{
                opacity: isChanged ? 1 : 0.5,
                cursor: isChanged ? "pointer" : "not-allowed ",
              }}
              disabled={!isChanged}
            >
              Refresh
            </button>
          </form>
        </div>
      </div>

      <div
        className="col-xl-8 col-sm-11 m-auto"
        ref={columnRef}
        style={{ position: "relative" }}
      >
        <section className="logos_grid_section" ref={logosGridRef}>
          <div className="row m-0 p-sm-0 px-2" style={{ overflow: "auto" }}>
            {logos && logos.length === 0 ? (
              <div>Loading...</div>
            ) : logos && logos.length > 0 ? (
              logos.map((logoData, index) => (
                <div
                  className={`col-lg-3 col-md-4 col-sm-6 mx-sm-0 p-2 center-xs center-sm  MarginBtttmm ${
                    window.innerWidth < 350 ? "col-12" : "col-6"
                  }`}
                  //ref={columnRef}
                  // style={{
                    // height: window.innerWidth < 576 ? "100%" : "",
                  //   width: window.innerWidth < 576 ? 280 : "",
                  // }}
                  key={index}
                >
                  <div
                    className="grid-item girdLogo_item"
                    style={{
                      width: colWidth,
                      height:
                        window.innerWidth < 450 ? colWidth + 20 : colWidth,
                      position: "relative",
                    }}
                  >
                    <canvas
                      ref={(ref) =>
                        handleCanvasRef(
                          ref,
                          logoData,
                          index,
                          colWidth,
                          allResponses,
                          logoData.different_color_shade,
                          (iincment = iincment + 1000)
                        )
                      }
                      className="adjustwidthcls"
                      style={{
                        display: loadingStates[index] ? "none" : "block",
                      }}
                    />

                    <canvas
                      ref={(ref) =>
                        handleCanvasRef2(
                          ref,
                          logoData,
                          index,
                          colWidth,
                          allResponses,
                          logoData.different_color_shade,
                          (iincment = iincment + 1000)
                        )
                      }
                    />
                    {loadingStates[index] && (
                      <div
                        className="canvas-loader"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoderIcon />
                      </div>
                    )}
                    {/* 
                    {showLoaderAdmin && (
                      <div className="loader-overlay">
                        <ShowLogoLoader />
                      </div>
                    )} */}

                    <div className="logoFoot">
                      <button
                        className="editLogoBtn"
                        onClick={() => handleEditSaveCanvas(logoData, index)}
                      >
                        {!isLoaderVisible ? (
                          <p className="m-0" style={{ whiteSpace: "nowrap" }}>
                            Edit Logo
                          </p>
                        ) : null}
                      </button>

                      <div className="HeartDownload">
                        <button
                          className="saveLogoBtn"
                          onClick={() =>
                            handleLikeClick(index, logoData.primary_id)
                          }
                        >
                          {likedHeardLoader[index] ? (
                            <img
                              className="LoaderGif"
                              src={transpretLoader}
                              alt="Loading..."
                            />
                          ) : (
                            <img
                              src={likedLogos[index] ? hearticonRed : Heart}
                              alt="Saving Logo"
                            />
                          )}
                        </button>
                        <button
                          className="saveLogoBtn"
                          onClick={() => {
                            toggleVisibility(
                              index,
                              logoData.primary_id,
                              JSON.parse(logoData.template_canvas)
                            );
                          }}
                          // onClick={() => handleEditClick("", index, "", 2)}
                        >
                          <img
                            className="downloadbtn"
                            src={Download}
                            alt="Download Logo"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-100 d-flex justify-content-center mt-4">
                {/* No logos available */}
              </div>
            )}
          </div>
          <div className="LoaderSHowLogo">
            {/* {showLoaderAdmin &&  <LoderIcon/>  } */}
            {showLoaderAdmin2 && <LoderIcon />}
          </div>
        </section>
        <div className={`downloadTemplates ${isVisible ? "visible" : ""}`}>
          <div className="crossDownloadicon">
            <button
              onClick={handlesectionDisable}
              style={{ background: "transparent", border: "none" }}
            >
              <img src={crossIcn} alt="cross img"></img>
            </button>
          </div>

          <div className="container">
            <ul>
              <li>
                <div className="firstResize">
                  <div className="HeadingDownload">
                    <div className="d-flex gap-2 alignment-items-center">
                      <p>Download Lite</p>
                      <span>Package: Business</span>
                    </div>
                  </div>
                  <div className="w-100 mt-2">
                    <select
                      value={selectedFormat}
                      onChange={handleFormatChange}
                    >
                      <option value="" disabled hidden>
                        Select format{" "}
                      </option>
                      <option value="png">PNG</option>
                      <option value="svg">SVG</option>
                      <option value="pdf">PDF</option>
                    </select>
                  </div>
                  {showImgSizeRange && (
                    <>
                      <div className="imgHead" style={{ paddingTop: "1rem" }}>
                        <label>Image size</label>
                        <span>
                          {rangeValue}x{rangeValue}px
                        </span>
                      </div>
                      <div className="downloadRenge">
                        <input
                          className="rngePIXL"
                          type="range"
                          min="200"
                          max="2000"
                          step="5"
                          defaultValue={rangeValue}
                          onChange={(e) => handleRangeChange(e)}
                        />
                      </div>
                    </>
                  )}
                  <div className="downloadRenge2">
                    <input
                      type="checkbox"
                      id="transparentBg"
                      name="transparentBg"
                      checked={transparentBackground}
                      onChange={(e) => {
                        setTransparentBackground(e.target.checked);
                        console.log("Checkbox checked:", e.target.checked);
                      }}
                    />
                    <label> Transparent Background</label>
                  </div>{" "}
                  <div className="col">
                    <div className="downloadTemplateBtnn">
                      <button onClick={handleDownload}>Download</button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/*-----------Modal for favorate Template Delete----*/}
      <Modal
        show={showModal}
        onHide={handleCancelUnclick}
        centered
        className="LiketemplateModal"
      >
        <Modal.Body>
          <h3>Are you sure you want to delete that design?</h3>
          <p>Design will be removed from your list</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100  ModalCencellDltButton">
            <button onClick={handleCancelUnclick}> Cancel</button>
            <button className="deleteFav" onClick={handleConfirmUnclick}>
              {" "}
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/**-----------download modall----*/}
      <Modal
        className="deleteModddl"
        show={modalMessage}
        onHide={handleDownloadModlCLose}
        centered
      >
        <Modal.Header closeButton>
          <div className="WarningHeader">Warning</div>
        </Modal.Header>
        <Modal.Body>
          <div className="DownloadFormetErrorMsg">
            Unsupported format. Please choose png, jpg, svg, or pdf.
          </div>
        </Modal.Body>
      </Modal>

      {/* Preparing Modal */}
      <Modal
        centered
        backdrop="static"
        className="modlePrepared"
        show={isLoaderVisible}
        onHide={() => setIsLoaderVisible(false)}
      >
        <Modal.Body>
          <div className="prePareDiv">
            <div className="LoaderPrepare">
              <ShowLogoLoader />
            </div>
            <p>Your design is being prepared. Please wait...</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShowLogos;
