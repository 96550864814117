import "./Css/PaymentInvoice.css";
import headerLogo from "../Assets/Images/headerLogo.png";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
import { useParams } from "react-router-dom";

const PaymentInvoice = ({
  handleDownloadinvoice,
  datasubscript,
  datacustomer,
}) => {
  const Custumerinfo = datacustomer;
  console.log("aada", Custumerinfo);
  const SubscriptDeatails = datasubscript;
  console.log(SubscriptDeatails);
  const full_name = retrieveData("full_name");
  const user_email = retrieveData("user_email");
  const entryDate = SubscriptDeatails.entry_date;

  // Create a new Date object
  const dateObject = new Date(entryDate);

  // Format the date
  const formattedDate = `${dateObject.getDate().toString().padStart(2, "0")}/${(
    dateObject.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${dateObject.getFullYear()}`;
  const Enddate = SubscriptDeatails.subscription_end_date;

  // Create a new Date object
  const dateObject2 = new Date(Enddate);

  // Format the date
  const formattedEndDate = `${dateObject2
    .getDate()
    .toString()
    .padStart(2, "0")}/${(dateObject2.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${dateObject2.getFullYear()}`;

  return (
    <>
      <div className="container">
        <div id="invoice" className="invoice">
          <div className="LogoTOp">
            {" "}
            <img
              className="logoImg"
              style={{ width: "195px", height: "40px", marginBottom: "0.8rem" }}
              src={headerLogo}
              alt="logo"
            ></img>{" "}
          </div>
          <div className="HeaderCOntainer">
            <div className="topContainer">
              <p style={{ fontWeight: "600", fontSize: "16px" }}>
                Invoice/Transaction No.
              </p>
              <p>#{SubscriptDeatails.payment_transaction_id}</p>
              <div className="DatwSection">
                <div className="d-flex ">
                  <p>
                    <label className="DateLebel" style={{ width: "90px" }}>
                      {" "}
                      Invoice Date: {""}
                    </label>
                    <strong>{formattedDate || ""}</strong>
                  </p>
                </div>
                <div className="d-flex ">
                  <p>
                    <label className="a" style={{ width: "90px" }}>
                      {" "}
                      Due Date: {""}
                    </label>
                    <strong>
                      {""}
                      {formattedEndDate}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="CenterContent">
            <div className="row">
              <div className="col-6">
                <label>Bill to</label>
                <p>{full_name}</p>
                <p>{user_email}</p>
              </div>
              <div className="col-6">
                <label>From</label>
                <p>Logo Acadeamy</p>
                <p>https://logoacademy.co</p>
              </div>
            </div>
          </div>
          <div className="d-flex contentLast">
            <label>
              {/* This is an invoice sample. This message is set as default. You can
            always change it to whatever you like. Feel free.{" "} */}
            </label>
          </div>
          <table
            className="table Invvtable"
            style={{ marginBottom: "0", fontFamily: "DM Sans" }}
          >
            <thead>
              <tr>
                <th style={{ color: "black" }} colSpan={2}>
                  Description
                </th>
                {/* <th style={{ color: "black" }}>Quantity</th> */}
                <th style={{ color: "black" }}>Price in ₹</th>
                <th style={{ color: "black" }}>Amount in ₹</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <strong>{SubscriptDeatails.package_name}</strong>
                  </div>
                </td>
                {/* <td></td> */}
                <td>{SubscriptDeatails.package_price}</td>
                <td>{SubscriptDeatails.package_price}</td>
              </tr>
            </tbody>
          </table>
          <div className="SummeryInvoice">
            <div className="contentBox"></div>
            <div className="contenrLastSummery">
              <div
                className="borderBtttm"
                style={{ borderTop: "1px solid #ebeef6" }}
              >
                <label>Subtotal</label>
                <p style={{ fontWeight: "600" }}>
                  ₹{SubscriptDeatails.package_price}/-
                </p>
              </div>
              <div className="borderBtttm">
                <label>Total Including tax</label>
                <p>₹{SubscriptDeatails.package_price}/-</p>
              </div>
              <div className="borderBtttm">
                <label>Discount </label>
                <p>₹0/-</p>
              </div>
              <div className="borderBtttm">
                <label>Total</label>
                <p> ₹{SubscriptDeatails.package_price}/-</p>
              </div>
              <div className="borderBtttm">
                <label style={{ fontWeight: "600" }}>Amout Due</label>
                <p style={{ fontWeight: "600" }}>
                  ₹{SubscriptDeatails.package_price}/-
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex contentLast">
            <label>
              {/* This is a footer sample. You can add a special message to your user
            to thank them. */}
            </label>
          </div>
          {/* <div className="FooterCOntent">
          <label>#27346733-022 · $93,100 due 7 March, 2023</label>
          <label>Page 1 of 1</label>
        </div> */}
        </div>
      </div>
      <div className="PrintBTn">
        <button type="button" onClick={handleDownloadinvoice}>
          Print/Download
        </button>
      </div>
    </>
  );
};

export default PaymentInvoice;
