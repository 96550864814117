import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fabric } from "fabric";
import crossIcn from "../Assets/Images/crossIcon.png";
import { jsPDF } from "jspdf";
import Header from "./Header";
import TabsHeader from "./TabsHeader";
import { Modal } from "react-bootstrap";
import "./Css/LoggedInHome.css";
import downIocn from "../Assets/Images/download_svg.svg";
import settingsIcon from "../Assets/Images/threeDotNav.svg";
import duplicateIcon from "../Assets/Images/copy_svg.svg";
import openinEditor from "../Assets/Images/pen_svg.svg";
import deleteSvg from "../Assets/Images/dlt_svg.svg";

import axios from "axios";
import {
  server_post_data,
  get_home_data,
  delete_user_templates,
  save_update_user_templates_dublicate,
} from "../ServiceConnection/serviceconnection.js";
import {
  retrieveData,
  removeData,
} from "../LocalConnection/LocalConnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";

let mainCanvas;
let newcanvas;
let refdonwload;
let canvas_temp_download;
const MyDesigns = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownIndex, setisDropdownIndex] = useState("");
  const active_package = retrieveData("active_package");
  const business_Card = retrieveData("business_card");
  const package_name = retrieveData("package_name");
  const subscription_active = retrieveData("subscription_active");
  const business_card = retrieveData("business_card");
  const downloading_rights = retrieveData("downloading_rights");
  const resolution = retrieveData("resolution");
  const vector_file = retrieveData("vector_file");
  const transparent_background2 = retrieveData("transparent_background");
  const [images3, setimages3] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [delete_id, setdelete_id] = useState([]);
  const [transparentBackground, setTransparentBackground] = useState(false);
  const [showImgSizeRange, setshowImgSizeRange] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [showOverLay, setshowOverLay] = useState(false);

  const [format, setFormat] = useState("");
  const dropdownRef = useRef(null);
  const toggleDropdown = (index) => {
    setIsDropdownOpen(!isDropdownOpen);
    setisDropdownIndex(index);
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", "3");

    const user_id = retrieveData("user_id");
    fd.append("user_id", user_id);
    try {
      const response = await server_post_data(get_home_data, fd);
      const responseData = response.data;

      //console.log(responseData.logo_templates);
      //setimages2(responseData.logo_templates);
      setimages3(responseData.other_templates);
      setshowLoaderAdmin(false);
    } catch (error) {
      setshowLoaderAdmin(false);
      handleError("network");
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleConfirmDelete = async () => {
    // Perform the delete action
    console.log(delete_id);
    setShowDeleteModal(false);
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", "1");
    fd.append("primary_id", delete_id);
    const user_id = retrieveData("user_id");
    fd.append("user_id", user_id);
    server_post_data(delete_user_templates, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          document.getElementById("div" + delete_id).remove();
        }
      })
      .catch((error) => {
        handleError("network");
      });
  };
  const handleOpenDelete = (primry_id) => {
    // Perform the delete action
    ///console.log(primry_id);
    setdelete_id(primry_id);
    console.log("handle open delete action is hitting");
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleOpenInEditor = (primary_id) => {
    console.log("primaryIdis", primary_id);
    navigate(`/canvasEdit/logo/${primary_id}`);
  };

  const handleDuplicateOpenInEditor = async (primary_id) => {
    let fd_from = new FormData();
    const user_id = retrieveData("user_id");
    fd_from.append("customer_id", user_id);
    fd_from.append("main_id", "0");
    fd_from.append("primary_id", primary_id);

    //setshowLoaderAdmin(true);
    try {
      const Response = await axios.post(
        save_update_user_templates_dublicate,
        fd_from,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the Content-Type header for form data
          },
        }
      );
      if (Response.status === 200) {
        //await server_post_data(save_update_user_templates, fd_from).then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          //handleSuccess(Response.data.message);
          navigate(`/canvasEdit/logo/${Response.data.last_inserted_id}`);
        }
      }
    } catch (error) {
      handleError("network");
    }
  };

  const [DownloadCanvas, setDownloadCanvas] = useState(null);
  const toggleVisibility = (primary_id, logodata) => {
    console.log("clicked");
    setIsVisible(!isVisible);
    setshowOverLay(!showOverLay);
    setDownloadCanvas(logodata);
  };

  const handlesectionDisable = () => {
    setIsVisible(!isVisible);
    setshowOverLay(false);
  };

  const templateparseGradient = (gradientString) => {
    const gradientRegex = /linear-gradient\(([^,]+),(.+)\)/;
    const matches = gradientString.match(gradientRegex);

    if (matches) {
      const direction = matches[1].trim();
      const colors = matches[2].split(",").map((color) => color.trim());
      return { direction, colors };
    }

    // Default case if regex does not match (fallback)
    return { direction: "to right", colors: [gradientString] };
  };

  const setCanvasBackground = (canvas, background) => {
    if (background.startsWith("linear-gradient")) {
      const { direction, colors } = templateparseGradient(background);

      let x1 = 0,
        y1 = 0,
        x2 = 0,
        y2 = 0;
      if (direction.includes("to right")) {
        x2 = canvas.width;
      } else if (direction.includes("to bottom")) {
        y2 = canvas.height;
      } else if (direction.includes("to left")) {
        x1 = canvas.width;
      } else if (direction.includes("to top")) {
        y1 = canvas.height;
      }

      const gradient = new fabric.Gradient({
        type: "linear",
        gradientUnits: "pixels",
        coords: { x1, y1, x2, y2 },
        colorStops: colors.map((color, index) => ({
          offset: index / (colors.length - 1),
          color,
        })),
      });

      canvas.setBackgroundColor(gradient, canvas.renderAll.bind(canvas));
    } else {
      canvas.setBackgroundColor(background, canvas.renderAll.bind(canvas));
    }
  };

  const [rangeValue, setRangeValue] = useState("200");
  useEffect(() => {}, [rangeValue]);

  const createCanvasnew = (logoData) => {
    return new Promise((resolve) => {
      if (!logoData) return;

      let canvasWidth;
      let canvasHeight;
      let canvasOrginalWidth;
      let canvasOrginalHeight;
      let background = "#000";

      canvasWidth = rangeValue;
      canvasHeight = rangeValue;
      background = logoData.background;
      canvasOrginalWidth = logoData.width;
      canvasOrginalHeight = logoData.height;
      canvas_temp_download = new fabric.Canvas(refdonwload, {
        width: canvasWidth,
        height: canvasHeight,
        borderColor: "#000",
        borderWidth: 2,
      });

      setCanvasBackground(canvas_temp_download, background);

      const scaleFactorWidth = canvasWidth / canvasOrginalWidth;
      const scaleFactorHeight = canvasHeight / canvasOrginalHeight;
      const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

      const renderNextObject = async (index) => {
        if (index >= logoData.objects.length) {
          resolve(canvas_temp_download); // Resolve the promise when rendering is complete
          return;
        }
        const objData = logoData.objects[index];
        if (objData.type === "i-text" && objData.metadata) {
          const customWidth = objData.customWidth || objData.width;
          objData.customWidth = customWidth;
        }

        const scaledObject = {
          ...objData,
          scaleX: (objData.scaleX || 1) * scaleFactor,
          scaleY: (objData.scaleY || 1) * scaleFactor,
          left: (objData.left || 0) * scaleFactor,
          top: (objData.top || 0) * scaleFactor,
        };

        fabric.util.enlivenObjects([scaledObject], (objects) => {
          objects.forEach((obj) => {
            obj.selectable = false;
            if (obj.type === "i-text") {
              const customWidth = obj.customWidth || obj.width;
              obj.set({ textAlign: obj.textAlign });
              obj.set({
                width: customWidth,
              });
            }
            canvas_temp_download.add(obj);
          });
          canvas_temp_download.requestRenderAll();
          renderNextObject(index + 1);
        });
      };

      renderNextObject(0);
    });
  };

  const handleFormatChange = (event) => {
    const selectedFormat = event.target.value;
    setFormat(selectedFormat);
    if (selectedFormat === "png") {
      setshowImgSizeRange(true);
    } else {
      setshowImgSizeRange(false);
    }
  };

  const downloadCanvasTemplate = async () => {
    await createCanvasnew(DownloadCanvas).then((canvasnew) => {
      newcanvas = canvasnew;

      switch (format) {
        case "png":
          downloadAsPNG();
          break;
        case "jpg":
          //downloadAsJPG();
          break;
        case "svg":
          const vectorFile = localStorage.getItem("vector_file");
          if (vectorFile === "Vectors File") {
            downloadAsSVG();
          } else {
            navigate("/pricing");
          }
          break;
        case "pdf":
          downloadAsPDF();
          break;
        default:
          handleDownloadFormetMsg();
      }
    });
  };

  let transparent_background = transparentBackground ? 1 : 0; // Set this dynamically as needed

  const downloadAsPNG = () => {
    const origBackgroundColor = newcanvas.backgroundColor;
    const origWatermarkUrl = "";
    const origWatermarkVisible = newcanvas.backgroundImage?.visible;

    // Temporarily make the watermark invisible
    if (newcanvas.backgroundImage) {
      newcanvas.backgroundImage.visible = false;
      newcanvas.renderAll();
    }

    // Set the background color to transparent if needed
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      const dataURL = newcanvas.toDataURL({
        format: "png",
        quality: 1.0,
        background: "transparent",
      });
      downloadAndSaveImage(dataURL, "image.png");
      newcanvas.setBackgroundColor(origBackgroundColor, () => {
        newcanvas.renderAll();
        if (newcanvas.backgroundImage) {
          newcanvas.backgroundImage.visible = origWatermarkVisible;
          newcanvas.renderAll();
        }
      });
    });
  };
  const downloadAsSVG = () => {
    const origBackgroundColor = newcanvas.backgroundColor;
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    // Set background color to transparent if needed
    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      if (newcanvas) {
        const svg = newcanvas.toSVG({
          reviver: (obj, instance) => {
            if (instance instanceof fabric.Object) {
              return instance.toSVG();
            }
            return obj;
          },
        });
        const blob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "canvas.svg";
        link.click();

        // Clean up
        URL.revokeObjectURL(url);

        // Reset background color
        newcanvas.setBackgroundColor(origBackgroundColor, () => {
          newcanvas.renderAll();
        });
      } else {
        console.error("mainCanvas is undefined, unable to download SVG");
      }
    });
  };

  const downloadAsPDF = () => {
    if (!newcanvas) return;

    const origBackgroundColor = newcanvas.backgroundColor;
    const backgroundColor =
      transparent_background === 1 ? "transparent" : origBackgroundColor;

    // Set background color to transparent if needed
    newcanvas.setBackgroundColor(backgroundColor, () => {
      newcanvas.renderAll();
      const canvasElement = newcanvas;
      const dataURL = canvasElement.toDataURL("image/png");

      const pdf = new jsPDF();
      pdf.addImage(dataURL, "PNG", 0, 0);
      pdf.save("canvas.pdf");

      // Reset background color
      newcanvas.setBackgroundColor(origBackgroundColor, () => {
        newcanvas.renderAll();
      });
    });
  };

  const downloadAndSaveImage = (dataURL, filename) => {
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = filename;
    link.click();
  };
  const handleDownloadFormetMsg = () => {
    console.log("handleDownloadFormetMsgdadadad");
    setModalMessage(true);
  };
  const handleDownloadModlCLose = () => {
    setModalMessage(false);
  };
  const handleRangeChange = (event) => {
    const newValue = Number(event.target.value);
    setRangeValue(newValue);
  };
  return (
    <div>
      <Header />

      <div className="container-lg">
        <TabsHeader />
        <div className="container">
          <div className="row p-0 ImgGap">
            {images3.map((imagex, index) => (
              <div
                key={index}
                className="col-12 col-md-6 col-lg-4 p-0 imageitem"
                id={`div${imagex.primary_id}`}
              >
                <div className="image_tag">{imagex.template_type}</div>
                <img
                  src={`https://api.logoacademy.co/assets/${imagex.template_url}`}
                  alt={imagex.template_type}
                />
                <div
                  className="settings_icon"
                  onClick={() => {
                    toggleDropdown(index);
                  }}
                  // ref={dropdownRef}
                >
                  <img
                    src={settingsIcon}
                    alt="settings"
                    className="settings_img"
                  />
                  {isDropdownOpen && isDropdownIndex === index && (
                    <div className="dropdown_menuu HomeLogoTempOption">
                      <button
                        onClick={() => {
                          toggleVisibility(
                            imagex.primary_id,
                            JSON.parse(imagex.template_canvas)
                          );
                        }}
                        disabled={
                          subscription_active == 0 || downloading_rights == ""
                        }
                      >
                        <img src={downIocn} alt="downLoadIcon" />
                        Download
                      </button>
                      <button
                        onClick={() => {
                          handleDuplicateOpenInEditor(imagex.primary_id);
                        }}
                      >
                        <img src={duplicateIcon} alt="duplicateIcon" />
                        Duplicate Icon
                      </button>
                      <button
                        onClick={() => {
                          handleOpenInEditor(imagex.primary_id);
                          console.log("sdfsdfsfsfsfsdfsdfsdfsd");
                        }}
                      >
                        <img src={openinEditor} alt="openinEditor" />
                        Open in editor
                      </button>
                      <button
                        onClick={() => {
                          handleOpenDelete(imagex.primary_id);
                        }}
                      >
                        <img src={deleteSvg} alt="deleteSvg" />
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showOverLay && (
        <div className={`overLayCLass ${isVisible ? "show" : ""}`}></div>
      )}
      <div className={`downloadTemplates ${isVisible ? "visible" : ""}`}>
        <div className="crossDownloadicon">
          <button
            onClick={handlesectionDisable}
            style={{ background: "transparent", border: "none" }}
          >
            <img src={crossIcn} alt="cross img"></img>
          </button>
        </div>

        <div className="container">
          <ul>
            <li>
              <div className="firstResize">
                <div className="HeadingDownload">
                  <div className="d-flex gap-2 alignment-items-center">
                    <p>Download</p>
                    <span>Package: {package_name}</span>
                  </div>
                </div>
                <div className="w-100 mt-2">
                  <select value={format} onChange={handleFormatChange}>
                    <option value="" disabled hidden>
                      Select format
                    </option>
                    <option value="png">PNG</option>

                    <option
                      value="svg"
                      disabled={subscription_active == 0 || vector_file == ""}
                    >
                      SVG
                    </option>
                    <option value="pdf">PDF</option>
                  </select>
                </div>
                {showImgSizeRange && (
                  <>
                    <div className="imgHead" style={{ paddingTop: "1rem" }}>
                      <label>Image size</label>
                      <span>
                        {rangeValue}x{rangeValue}px
                      </span>
                    </div>
                    <div className="downloadRenge">
                      <input
                        className="rngePIXL"
                        type="range"
                        min="200"
                        max="2000"
                        step="5"
                        defaultValue={rangeValue}
                        onChange={(e) => handleRangeChange(e)}
                        disabled={subscription_active == 0 || resolution == ""}
                      />
                    </div>
                  </>
                )}
                <div className="downloadRenge2">
                  <input
                    type="checkbox"
                    id="transparentBg"
                    name="transparentBg"
                    checked={transparentBackground}
                    onChange={(e) => {
                      setTransparentBackground(e.target.checked);
                      console.log("Checkbox checked:", e.target.checked);
                    }}
                    disabled={
                      subscription_active == 0 || transparent_background2 == ""
                    }
                  />
                  <label> Transparent Background</label>
                </div>{" "}
                <div className="col">
                  <div className="downloadTemplateBtnn">
                    <button
                      onClick={downloadCanvasTemplate}
                      disabled={
                        subscription_active == 0 || downloading_rights == ""
                      }
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* delete design modal */}
      <Modal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        className="delete_modal_wrapper fade"
        centered
      >
        <div className="delete_modal">
          <Modal.Body className="delete_modal_bodycontainer">
            <div className="delete_modal_body">
              <h5> Are you sure you want to delete that design?</h5>
              <h6>Design will be removed from your list</h6>
              <div className="delete_modal_buttons">
                <button
                  onClick={() => {
                    handleCloseDeleteModal();
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    handleConfirmDelete();
                    setShowDeleteModal(false);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        className="deleteModddl"
        show={modalMessage}
        onHide={handleDownloadModlCLose}
        centered
      >
        <Modal.Header closeButton>
          <div className="WarningHeader">Warning</div>
        </Modal.Header>
        <Modal.Body>
          <div className="DownloadFormetErrorMsg">
            Unsupported format. Please choose png, jpg, svg, or pdf.
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MyDesigns;
