import React, { useState, useEffect } from "react";
import "./Css/BrandKit.css";
import Header from "./Header";
import { Link, useLocation } from "react-router-dom";
import logoImg from "../Assets/Images/logoImg.png";
import pen from "../Assets/Images/penIcon.svg";
import { Button, Modal } from "react-bootstrap";

import {
  handleEmailChange,
  check_vaild_save,
  combiled_form_data,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handleURLChange,
  handleAphabetsChange,
  handleError,
  handleLinkClick,
  empty_form,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_update_brandkit,
  get_home_data,
  update_logo_to_brandkit,
  get_brandkit_by_user,
} from "../ServiceConnection/serviceconnection.js";
import { retrieveData,storeData } from "../LocalConnection/LocalConnection.js";
import Loader from "./Loader.js";
const BrandKit = () => {
  const navigate = useLocation();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [images2, setimages2] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedLogo, setSelectedLogo] = useState();
  const [brandkitData, setBrandkitdat] = useState();

  useEffect(() => {
    master_data_get_brandkit();
    master_data_get();
  }, []);

  // const handleFileChangedynamic = (keyname) => (event) => {
  //   const file = event.target.files[0];
  //   let new_file_name = keyname + "_show";

  //   if (!file) {
  //     alert("Please select an image.");
  //     return;
  //   }

  //   if (file.type.startsWith("image/")) {
  //     // Validate file size
  //     if (file.size < 200 * 1024) {
  //       // 200KB in bytes
  //       handleError("File size is below the minimum limit (200KB).");
  //       return;
  //     }

  //     if (file.size > 500 * 1024) {
  //       // 500KB in bytes
  //       handleError("File size exceeds the maximum limit (500KB).");
  //       return;
  //     }

  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       const img = new Image();
  //       img.src = reader.result;

  //       setDynaicimage((prevImages) => ({
  //         ...prevImages,
  //         [keyname]: file,
  //         [new_file_name]: reader.result,
  //       }));
  //     };

  //     reader.readAsDataURL(file);
  //   } else {
  //     handleError("Please select a valid image file.");
  //     event.target.value = ""; // Clear the file input
  //   }
  // };

  // console.log(brandkitData.company_name);

  const templateUrls = (images2 || []).map((image) => ({
    template_url: image.template_url,
    primary_id: image.primary_id,
  }));
  ////console.log(templateUrls);

  const handleSlelectImage = (template_url, primary_id) => {
    setSelectedImage(template_url); // Set the selected image URL
    setSelectedLogo(primary_id);
    console.log("Selected primary_id:", primary_id); // Log the primary_id
    setshowModal(false); // Close the modal
  };

  // Function to handle saving changes and sending data to backend
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      console.log(vaild_data);
      setshowLoaderAdmin(true);

      // Retrieve user_id from local storage
      const user_id = retrieveData("user_id"); // Assuming retrieveData is a function to get data from local storage

      // Prepare the form data
      let fd_from = combiled_form_data(form_data, dynaicimage);

      // Append user_id to form data
      fd_from.append("user_id", user_id);

      // Append the logo image to the form data if it exists
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            empty_form(form_data);
            // handleSuccessSession(Response.data.message, );
            storeData('brandkit_templates','https://api.logoacademy.co/assets/'+selectedImage);
            
            handleLinkClick("/home");
          }
          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          handleError("network");
          setshowLoaderAdmin(false);
        });
    }
  };

  const Update_brandkit = async (primary_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    // Append primary_id as main_id to FormData
    if (primary_id) {
      fd.append("primary_id", primary_id);
    } else {
      console.error("Primary ID is missing.");
      setshowLoaderAdmin(false);
      return;
    }

    // Retrieve user_id from local storage
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      fd.append("user_id", user_id);
    } else {
      console.error("User ID is not available in local storage.");
      setshowLoaderAdmin(false);
      return;
    }

    try {
      const response = await server_post_data(update_logo_to_brandkit, fd);
      const responseData = response.data;

      setimages2(responseData.logo_templates);
      // setimages3(responseData.other_templates);
      setshowLoaderAdmin(false);
    } catch (error) {
      setshowLoaderAdmin(false);
      handleError("network");
    }
  };

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", "2");

    const user_id = retrieveData("user_id");
    fd.append("user_id", user_id);
    try {
      const response = await server_post_data(get_home_data, fd);
      const responseData = response.data;

      setimages2(responseData.logo_templates);
      // setimages3(responseData.other_templates);
      setshowLoaderAdmin(false);
    } catch (error) {
      setshowLoaderAdmin(false);
      handleError("network");
    }
  };

  const master_data_get_brandkit = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", "1");

    const user_id = retrieveData("user_id");
    fd.append("user_id", user_id);
    try {
      const response = await server_post_data(get_brandkit_by_user, fd);
      const responseData = response.data.data[0];
      setSelectedImage(response.data.data[0].company_logo);
      setBrandkitdat(responseData);
      setshowLoaderAdmin(false);
    } catch (error) {
      setshowLoaderAdmin(false);
      //handleError("network");
    }
  };

  return (
    <>
      <Header />
      {showLoaderAdmin && <Loader />}
      <div className="container">
        {" "}
        <section className="brandkit_section">
          <div className="container">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="brandkit_heading_wrapper brnDkitSize">
                <div className="brandkit_heading_links">
                  <Link to="/home">
                    {" "}
                    <a>Home</a>
                  </Link>
                  <h6>/ Brand kit</h6>
                </div>
                <form id="brandkit_form">
                  <div className="brandkit_heading_title TitleDiv">
                    <h3>Fill in your company details</h3>
                    <p>
                      We will pre-fill branded designs with this data. In the
                      future you will be able to update this data and the
                      materials will be updated automatically.
                    </p>
                  </div>
                  <div className="brandkit_company_name">
                    <lable>Company name</lable>
                    <input
                      name="company_name"
                      placeholder="Type company name"
                      className="trio_mandatory form-control input_field_custom"
                      defaultValue={brandkitData && brandkitData.company_name}
                      maxLength={75}
                      minLength={2}
                      onInput={handleIaphabetnumberChange}
                      autoFocus
                      tabIndex={1}
                    />
                    <span className="condition_error"></span>
                  </div>
                  <div className="brandkit_logo_wrapper">
                    <label id="logo_text">
                      <strong>Logo</strong>
                    </label>
                    <img
                      src={
                        selectedImage
                          ? `https://api.logoacademy.co/assets/${selectedImage}`
                          : logoImg
                      }
                      alt="Logo Preview"
                      onError={logoImg}
                      width={"180px"}
                      // className="gallery-image template_radius "
                    />
                    {/* Use selected image if available, otherwise use default logoImg */}
                    <div className="brand_kit_button brand_kit_buttonnn">
                      <img
                        style={{ marginRight: "4px" }}
                        src={pen}
                        alt="penicon"
                      />
                      <label
                        htmlFor="company_logo"
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={() => setshowModal(true)}
                      >
                        Change
                        <input
                          name="company_logo"
                          className="trio_mandatory form-control input_field_custom"
                          type="text"
                          // id="company_logo"
                          value={selectedImage}
                          style={{ display: "none" }}
                          // onChange={handleFileChangedynamic("company_logo")}
                        />
                      </label>
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div
                    className="company_contacts_wrapper col-lg-12 "
                    style={{ paddingTop: "2rem" }}
                  >
                    <h5>Company contacts</h5>
                    <div className=" company_contacts_phone_email_wrapper">
                      <div className="brandKit_input_div">
                        <label>Phone</label>
                        <input
                          name="phone_number"
                          placeholder="Type company phone"
                          className="validateMobile form-control input_field_custom"
                          maxLength={12}
                          minLength={2}
                          defaultValue={
                            brandkitData && brandkitData.phone_number
                          }
                          onInput={handleNumbersChange}
                          autoFocus
                          tabIndex={1}
                        />
                        <span className="condition_error"></span>
                      </div>
                      <div className="brandKit_input_div">
                        <label>Email</label>
                        <input
                          name="email_id"
                          placeholder="Type company email"
                          className=" input_field_custom"
                          maxLength={75}
                          minLength={3}
                          defaultValue={brandkitData && brandkitData.email_id}
                          onInput={(e) => handleEmailChange(e)}
                          autoFocus
                          tabIndex={1}
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                    <div className="company_contacts_phone_email_wrapper">
                      <div className="brandKit_input_div">
                        <label>Link to site(Paste URL)</label>
                        <input
                          name="site_link"
                          placeholder="Type website Url"
                          className=" form-control input_field_custom brandkit_company_link"
                          maxLength={300}
                          minLength={2}
                          defaultValue={brandkitData && brandkitData.site_link}
                          onInput={handleURLChange}
                          autoFocus
                          tabIndex={1}
                        />
                        <span className="condition_error"></span>
                      </div>
                      <div className="brandKit_input_div">
                        <label>Facebook Profile(Paste URL)</label>
                        <input
                          name="facebook_profile"
                          placeholder="Enter Facebook Profile"
                          className=" form-control input_field_custom"
                          maxLength={300}
                          minLength={2}
                          defaultValue={
                            brandkitData && brandkitData.facebook_profile
                          }
                          onInput={handleURLChange}
                          autoFocus
                          tabIndex={1}
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                    <div className="company_contacts_phone_email_wrapper">
                      <div className="brandKit_input_div">
                        <label>Instagram Profile(Paste URL)</label>
                        <input
                          name="instagram_profile"
                          placeholder="Enter Instagram Profile"
                          className=" form-control input_field_custom"
                          maxLength={300}
                          minLength={2}
                          defaultValue={
                            brandkitData && brandkitData.instagram_profile
                          }
                          onInput={handleURLChange}
                          autoFocus
                          tabIndex={1}
                        />
                        <span className="condition_error"></span>
                      </div>
                      <div className="brandKit_input_div">
                        <label>LinkedIn Profile(Paste URL)</label>
                        <input
                          name="linkedIn_profile"
                          placeholder="Enter LinkedIn Profile"
                          className=" form-control input_field_custom"
                          maxLength={300}
                          minLength={2}
                          onInput={handleURLChange}
                          defaultValue={
                            brandkitData && brandkitData.linkedIn_profile
                          }
                          autoFocus
                          tabIndex={1}
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                    <div className="brandKit_input_div " id="address_brandkit">
                      <label>Address, additional information</label>
                      <input
                        name="Address"
                        placeholder="Enter Address here ..."
                        className=" form-control input_field_custom"
                        maxLength={200}
                        minLength={2}
                        defaultValue={brandkitData && brandkitData.Address}
                        autoFocus
                        onInput={handleIaphabetnumberChange}
                        tabIndex={1}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="personal_contacts_wrapper col-lg-12">
                    <h5>Personal Data</h5>
                    <div className="company_contacts_phone_email_wrapper">
                      <div className="brandKit_input_div">
                        <label>Your Name</label>
                        <input
                          name="personal_data_name"
                          placeholder="Type your name"
                          className="trio_mandatory form-control input_field_custom "
                          maxLength={75}
                          minLength={2}
                          onInput={handleAphabetsChange}
                          defaultValue={
                            brandkitData && brandkitData.personal_data_name
                          }
                          autoFocus
                          tabIndex={1}
                        />
                        <span className="condition_error"></span>
                      </div>
                      <div className="brandKit_input_div">
                        <label>Email</label>
                        <input
                          name="personal_data_organisation_email_id"
                          placeholder="Type your Email"
                          className=" form-control input_field_custom "
                          maxLength={75}
                          minLength={2}
                          defaultValue={
                            brandkitData &&
                            brandkitData.personal_data_organisation_email_id
                          }
                          onInput={handleEmailChange}
                          autoFocus
                          tabIndex={1}
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                    <div className="company_contacts_phone_email_wrapper">
                      <div className="brandKit_input_div">
                        <label>Personal phone (if different)</label>
                        <input
                          name="personal_data_phone_number"
                          placeholder="Type your personal phone no."
                          className=" form-control input_field_custom"
                          maxLength={12}
                          minLength={2}
                          defaultValue={
                            brandkitData &&
                            brandkitData.personal_data_phone_number
                          }
                          onInput={handleNumbersChange}
                          autoFocus
                          tabIndex={1}
                        />
                        <span className="condition_error"></span>
                      </div>
                      <div className="brandKit_input_div">
                        <label>Personal email (if different)</label>
                        <input
                          name="personal_data_email_id"
                          placeholder="Type your personal email id"
                          className="trio_email  form-control input_field_custom"
                          maxLength={75}
                          minLength={3}
                          defaultValue={
                            brandkitData && brandkitData.personal_data_email_id
                          }
                          onInput={(e) => handleEmailChange(e)}
                          autoFocus
                          tabIndex={1}
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className="brandKit_btn">
                    <Button
                      variant="primary"
                      className="login-button modal-button "
                      onClick={async () => {
                        try {
                          const primaryId = selectedLogo; // Ensure this value is set appropriately
                          console.log(primaryId);

                          // If a primaryId (logo) is selected, update the brandkit
                          if (primaryId) {
                            await Update_brandkit(primaryId);
                          } else {
                            console.log(
                              "No logo selected, proceeding to save the form."
                            );
                          }

                          // Call handleSaveChangesdynamic regardless of whether a logo was selected or changed
                          await handleSaveChangesdynamic(
                            "brandkit_form",
                            save_update_brandkit
                          );
                        } catch (error) {
                          console.error("An error occurred:", error);
                        }
                      }}
                    >
                      Save Information
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={showModal}
          onHide={() => setshowModal(false)}
          className="fade modal-sm"
          centered
        >
          <Modal.Header closeButton>
            <h5>Choose Logo</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-scrollable-content mt-2">
              <div className="row m-0" style={{ paddingRight: "12px" }}>
                {templateUrls.map((item, index) => (
                  <div
                    className="col-6"
                    style={{ paddingRight: "0", marginBottom: "0.7rem" }}
                    key={index}
                  >
                    <div
                      className="logoImgDiv"
                      onClick={() =>
                        handleSlelectImage(item.template_url, item.primary_id)
                      }
                    >
                      <img
                        src={`https://api.logoacademy.co/assets/${item.template_url}`}
                        alt={`Logo ${index + 1}`}
                      />
                    </div>
                    <p className="detailed_logoideas_image-description">
                      {`Logo ${index + 1}`}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default BrandKit;
