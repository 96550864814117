import React from "react";
import "../Components/Css/Recommendation.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "../Components/Css/PickIndustry.css";
import Select from "react-select";
import Header from "./Header";
import { storeData } from "../LocalConnection/LocalConnection.js";
import { useNavigate } from "react-router-dom";
import {
  server_post_data,
  get_all_industry_data,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery.js";
import ContinueButtonLoader from "./ContinueButtonLoader.js";
let sesionclr = 0;
let lastElement;
const Pickindustry = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [options, setoptions] = useState([]);

  useEffect(() => {
    handleFetchIndustryData();
  }, []);
  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Function to get button text based on selected option
  const getButtonText = () => {
    return selectedOption ? "Continue" : "Skip This Step";
  };

  //bottom navbar button text changement
  const getButtonStyle = () => {
    return selectedOption ? "continue_btn2" : "skip_btn";
  };

  // style to dropdown menu of industries
  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "0.6rem 0rem",
      color: "var(--text-grey)",
      fontFamily: "Roboto",
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      padding: "0.6rem 0rem",
      fontFamily: "Roboto",
      color: "var(--text-grey)",
    }),
  };

  // storing industry in local storage
  const [industry, setIndustry] = useState("");

  window.addEventListener("beforeunload", function (event) {
    if (window.location.pathname === "/pickIndustry") {
      sessionStorage.setItem("reloadAttempt", "true");
      event.returnValue = "Are you sure you want to leave?";
    }
  });

  // On page load
  window.addEventListener("load", function () {
    if (window.location.pathname === "/pickIndustry") {
      const reloadAttempt = sessionStorage.getItem("reloa  dAttempt");
      const navEntries = performance.getEntriesByType("navigation");
      if (
        navEntries.length > 0 &&
        window.location.pathname === "/pickIndustry"
      ) {
        const navType = navEntries[0].type;

        if (reloadAttempt || navType === "reload") {
          console.log(
            "The page was reloaded (Ctrl+R, refresh button, or Enter in the URL bar)."
          );
          // Clear sessionStorage flag after detecting the reload
          sessionStorage.removeItem("reloadAttempt");
          storeData("companyName", "");
          storeData("card_colors", "");
          storeData("selectedIcons", "");
          storeData("slogan", "");
          storeData("related_industry", null);
          storeData("industry", null);
          storeData("selectedIndustry", "");
          sesionclr = 0;
          navigate("/createlogohome");
        }
      }
    }
  });

  const handleFetchIndustryData = async () => {
    var form_data = new FormData();
    await server_post_data(get_all_industry_data, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          const industryArray = Response.data.message.data.map(
            (item, index) => ({
              value: index,
              label: item.industry_name,
              relatedword: item.related_industry_name,
              primary_id: item.primary_id,
            })
          );
          setoptions(industryArray);
          const savedOption = localStorage.getItem("selectedIndustry");
          if (savedOption) {
            const parsedOption = JSON.parse(savedOption);
            console.log(savedOption);
            setSelectedOption(
              industryArray.find(
                (option) => option.value === parsedOption.value
              )
            );
          }
        }
      })
      .catch((error) => {
        handleError("error");
      });
  };

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      const value = selectedOption.value;
      setIndustry(value);
      handleOptionChange(selectedOption);
    } else {
      setIndustry("");
      handleOptionChange(null);
    }
  };

  const gettheindustry = () => {
    if (selectedOption) {
      storeData("related_industry", options[industry].relatedword);
      storeData(
        "industry",
        options[industry].label + "@" + options[industry].primary_id
      );
    } else {
      storeData("related_industry", null); // Set related industry to null
      storeData("industry", null); // Set industry to null
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate("/recommendation");
    }, 400);
  };

  return (
    <>
      {" "}
      <Header Showheader={false} />
      <section className="pickIndustry_section">
        <div className="container-lg h-100">
          <div className="row m-0 height80">
            <div className="col-xl-6 col-lg-7 col-md-8 m-auto">
              <div className="heading_section">
                <h3>Choose your company industry</h3>
                <h6>To generate custom logo designs</h6>
              </div>
              <div className="industries_drop_down_menu">
                {options && options.length > 0 && (
                  <Select
                    className="select_industries"
                    placeholder="Photography, Health, Restaurant ..."
                    value={selectedOption}
                    isClearable
                    onChange={(option) => {
                      setSelectedOption(option);
                      if (option) {
                        localStorage.setItem(
                          "selectedIndustry",
                          JSON.stringify(option)
                        );
                      } else {
                        localStorage.removeItem("selectedIndustry");
                      }
                    }}
                    options={options}
                    menuIsOpen={isMenuOpen}
                    onMenuClose={() => setIsMenuOpen(false)}
                    onMenuOpen={() => setIsMenuOpen(true)}
                    styles={customStyles}
                  />
                )}
              </div>
              <div className="col-md-10 col-sm-11 mx-auto">
                <div className="caption_container caption_container2 text-center">
                  <p>
                    Choosing an industry that is in line with your business area
                    will increase the quality of logo generation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="navbar_bottom">
        <button
          className={` ${getButtonStyle()}`}
          onClick={handleClick}
          type="button"
        >
          {isLoading ? (
            <div className="loader">
              <ContinueButtonLoader />
            </div>
          ) : (
            <p>{getButtonText()}</p>
          )}
        </button>
      </div>
    </>
  );
};

export default Pickindustry;
