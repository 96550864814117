import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Components/Css/ViewInvitationCard.css";
import Header from "../Components/Header";
import madeforyouImg from "../Assets/Images/made_for_you.svg";
import allTemplates from "../Assets/Images/all_templates.svg";
import pen from "../Assets/Images/penIcon.svg";
import "./Css/ViewLetterHead.css";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import docxIcon from "../Assets/Images/docxIcon.svg";
import pdficon from "../Assets/Images/PDFicon.svg";
import createBlank from "../Assets/Images/create_blank.svg";
import letterHeadTest from "../Assets/Images/letteHeadTest.png";
import docxTest from "../Assets/Images/docxImageTest.svg";
import Loader from "./Loader.js";
import {
  server_post_data,
  get_user_templete,
} from "../ServiceConnection/serviceconnection.js";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery";
const ViewLetterHead = () => {
  let template_type = "Letterhead";
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const docxsLetterHeads = [
    docxTest,
    docxTest,
    docxTest,
    docxTest,
    docxTest,
    docxTest,
  ];
  const [businessCards, setbusinessCards] = useState([]);
  useEffect(() => {
    master_data_get();
  }, []);
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    fd.append("template_type", template_type);
    await server_post_data(get_user_templete, fd)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data.length > 0) {
            setbusinessCards(Response.data.message.data);
            console.log("InitationsssCard", Response.data.message.data);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  console.log(businessCards);
  return (
    <div className="viewBusinesscard_section">
      {showLoaderAdmin && <Loader />}
      <Header Showheader={true} CheckLogin={true} />
      <div className="container-lg">
        <div className="businesscard_wrapper">
          <div className="brandkit_heading_links businessCard_heading_links">
            <span className="flexRow">
              <Link to="/home">Home</Link>
              <h6>/ Letterheads</h6>
            </span>
            <div className="businessCard_heading_buttons invitationCard_heading_buttons ">
              <div className="brand_kit_button brand_kit_buttonnn invitationCard_brand_kit_buttonnn">
                <img src={pen} alt="penicon" />
                <Link
                  style={{
                    color: "#666666",
                    fontWeight: "500",
                    fontSize: "14px",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  to="/brandkit"
                >
                  Brand Kit
                  <input
                    name="company_logo_new"
                    id="logoInput"
                    style={{ display: "none" }}
                  />
                </Link>
              </div>
            </div>
          </div>
          <p className="businessCard_dimensions">90x55mm</p>
          <div>
            <Tabs>
              <TabList>
                <Tab>
                  <img src={pdficon} id="pdfIcon" alt="pdfIcon" />
                  PDF
                </Tab>
                <Tab>
                  <img src={docxIcon} alt="pdfIcon" />
                  DocX
                </Tab>
              </TabList>

              <TabPanel>
                {/* Content for Tab 1 */}
                <div className="made_for_you_text_wrapper made_for_you_text_wrapper_letterHead">
                  <img src={madeforyouImg} alt="made for you Image" />
                  <p>Made for you</p>
                </div>
                <div className="row row_design_letterhead">
                  <div
                    className="col-lg-3 col-md-4 col-12"
                    style={{ width: "fit-content" }}
                  >
                    {/* <Link to={"/canvasEdit/letterhead/0"}>
                      <div className="create_blank_div create_blank_div_letterHead ">
                        <img src={createBlank} alt="create blank" />
                      </div>
                    </Link> */}
                    <div className="create_blank_div create_blank_div_letterHead ">
                      <img src={createBlank} alt="create blank" />
                    </div>
                  </div>
                  {/* {businessCards.map((businessImage, index) => (
                    <div className="col-lg-2 col-md-2" key={`Bcard-${index}`}>
                      <div
                        className="businessCards_images_div "
                        style={{ width: 200, height: 320 }}
                        // onClick={() =>
                        //   handleLinkClick(
                        //     "/canvasEdit/" +
                        //       template_type +
                        //       "/" +
                        //       businessImage.primary_id
                        //   )
                        // }
                      >
                        <img
                          src={`https://api.logoacademy.co/assets/${businessImage.template_url}`}
                          alt="favorite_logos"
                        />
                      </div>
                    </div>
                  ))} */}
                </div>

                <div className="made_for_you_text_wrapper mt-3">
                  <img src={allTemplates} alt="made for you" />
                  <p>All Templates</p>
                </div>

                <div className="row row_design_letterhead mt-3 mx-auto">
                  {businessCards.map((businessImage, index) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-4 col-6 p-2"
                      key={`Bcard-${index}`}
                    >
                      <div
                        className="businessCards_images_div "
                        style={{ width: "100%", borderRadius: "8px" }}
                        // onClick={() =>
                        //   handleLinkClick(
                        //     "/canvasEdit/" +
                        //       template_type +
                        //       "/" +
                        //       businessImage.primary_id
                        //   )
                        // }
                      >
                        <img
                          src={`https://api.logoacademy.co/assets/${businessImage.template_url}`}
                          alt="favorite_logos"
                          style={{ borderRadius: "8px" }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="invitationsCards_holder docxLetteHeads_holder">
                  {docxsLetterHeads.map((docxLetterHeadsImage, index) => (
                    <div
                      className="invitationCards_images_div"
                      key={`docxLetterHead-${index}`}
                    >
                      <img
                        src={docxLetterHeadsImage}
                        alt={`DocxLetterhead ${index + 1}`}
                      />
                      <p>Letterhead {index + 1}</p>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLetterHead;
