import axios from "axios";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
const appauth_key = "logoacedamy@2029";
let APL_LINK = "http://192.168.1.28:8000/";
APL_LINK = "https://api.logoacademy.co/";
//APL_LINK = "http://127.0.0.1:7998/";
let Website_URL = "https://www.logoacademy.co/";
let local_server_link_react = APL_LINK + "websitebackend/";

// API functions
//get last upload imgsn
const get_upload_imgs = local_server_link_react + "get_upload_img_data";
const insert_img_data = local_server_link_react + "save_update_upload_img";
const post_customfont_data = local_server_link_react + "post_customfont_data";
const get_icon_img = local_server_link_react + "call_noun_project_api";
const get_icon_shape = local_server_link_react + "get_icon_shape";
const Remove_upload_img = local_server_link_react + "delete_upload_image";
const get_all_faq = local_server_link_react + "get_all_faq";
const get_user_templete = local_server_link_react + "get_user_templete";
const get_package_by_id = local_server_link_react + "get_package_by_id";
const get_package_by_id_mk = local_server_link_react + "get_package_by_id_mk";

///////////admin/////////////
const login_to_superadmin = local_server_link_react + "login_to_superadmin";

/**website links */
const save_update_templates = local_server_link_react + "save_update_templates";
const get_template_data = local_server_link_react + "get_admin_template";
const get_admin_template_data = local_server_link_react + "get_admin_template";
const forgot_password = local_server_link_react + "forgot_password";
const check_resetlink_vaild = local_server_link_react + "check_resetlink_vaild";
const change_useremail_password =
  local_server_link_react + "change_useremail_password";
const get_admin_template_by_id =
  local_server_link_react + "get_admin_template_by_id";
const change_password_by_forgot =
  local_server_link_react + "change_password_by_forgot";
const user_login = local_server_link_react + "user_login";
const verifyuser = local_server_link_react + "verifyuser";
const gsign_in = local_server_link_react + "glogin";
const user_registration = local_server_link_react + "user_registration";
const forgotemail = local_server_link_react + "forgotemail";
const save_update_enquiry = local_server_link_react + "save_update_enquiry";
const save_update_brandkit = local_server_link_react + "save_update_brandkit";
const get_all_package_data = local_server_link_react + "get_all_package_data";
const get_blog_details_url = local_server_link_react + "get_blog_data_website";
const seo_data_url = local_server_link_react + "get_all_seo";
const get_blog_details_single =
  local_server_link_react + "blog_details_website";
const get_all_industry_data = local_server_link_react + "get_all_industry_data";
const get_all_industry_data_details =
  local_server_link_react + "get_all_industry_data_details";
const generate_color_themes = local_server_link_react + "generate_themes";
const get_all_testimonials = local_server_link_react + "get_testimonials";
const complete_payment_from_web =
  local_server_link_react + "complete_payment_from_web";
const get_all_pricing = local_server_link_react + "get_all_pricing";
const get_admin_template_showLogos =
  local_server_link_react + "get_admin_template_showLogos";
const get_canvas_industry_icon_data =
  local_server_link_react + "get_canvas_industry_icon_data";
const LastClickedIconsUrl = local_server_link_react + "LastClickedIconsUrl";
const call_noun_project_api_id =
  local_server_link_react + "call_noun_project_api_id";

const get_admin_template = local_server_link_react + "get_admin_template";
const intent_for_payment = local_server_link_react + "create_payment_intent";
const get_create_payment_details =
  local_server_link_react + "get_create_payment_details";
const get_user_information = local_server_link_react + "get_user_information";
const get_all_customer_booking =
  local_server_link_react + "get_all_customer_booking";
const get_home_page_data = local_server_link_react + "get_home_page_data";
const google_login_online =
  "https://www.googleapis.com/oauth2/v1/userinfo?access_token=";
const google_login_connect = local_server_link_react + "google_login_connect";
const google_login_disconnect =
  local_server_link_react + "google_login_disconnect";
const get_canvas_editor_data =
  local_server_link_react + "get_canvas_editor_data";
const get_canvas_editor_data_user =
  local_server_link_react + "get_canvas_editor_data_user";
const save_update_admin_templates =
  local_server_link_react + "save_update_admin_templates";
const save_update_user_templates =
  local_server_link_react + "save_update_templates";
const delete_user_templates = local_server_link_react + "delete_user_templates";
const get_package_by_customer_id =
  local_server_link_react + "get_package_by_customer_id";
const get_home_data = local_server_link_react + "get_home_data";
const save_update_user_templates_dublicate =
  local_server_link_react + "save_update_user_templates_dublicate";
const get_user_package_information =
  local_server_link_react + "get_user_package_information";
const FONT_URL = local_server_link_react + "get_all_font";
const get_admin_template_by_industry =
  local_server_link_react + "get_admin_template_by_industry";
const update_logo_to_brandkit =
  local_server_link_react + "update_logo_to_brandkit";
const get_brandkit_by_user = local_server_link_react + "get_brandkit_by_user";
const get_customer_all_package = local_server_link_react + "get_customer_all_package";

/**website links */
// Retrieving data

const user_id = retrieveData("user_id");
const StripeKey =
  "pk_test_51PVU9cP7GdOA24IdEhPhESdS6asxh2Ctyf28ru6lB2PrFInUsOwN97ThBPqp4OkLnxfkSZy3IEtIJIDrZaN1wKSb00s5VlmoGS";

const server_post_data = async (url_for, form_data) => {
  if (form_data === null) {
    form_data = new FormData();
  }
  if (user_id) {
    form_data.append("user_id", user_id);
  } else {
    form_data.append("user_id", 0);
  }
  return axios.post(url_for, form_data);
};
const server_get_data = async (url_for, form_data, access_token) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
    Accept: "application/json",
  };
  return axios.get(url_for + form_data, {
    headers: headers,
  });
};

export {
  APL_LINK,
  Website_URL,
  appauth_key,
  StripeKey,
  server_post_data,
  server_get_data,
  save_update_templates,
  get_template_data,
  login_to_superadmin,
  forgot_password,
  check_resetlink_vaild,
  change_password_by_forgot,
  get_upload_imgs,
  insert_img_data,
  user_login,
  gsign_in,
  user_registration,
  forgotemail,
  save_update_enquiry,
  save_update_brandkit,
  get_all_package_data,
  get_icon_shape,
  get_icon_img,
  get_blog_details_url,
  get_blog_details_single,
  seo_data_url,
  Remove_upload_img,
  get_all_industry_data,
  generate_color_themes,
  get_all_testimonials,
  change_useremail_password,
  get_admin_template_data,
  get_admin_template_showLogos,
  get_all_faq,
  get_canvas_industry_icon_data,
  LastClickedIconsUrl,
  get_admin_template,
  get_admin_template_by_id,
  google_login_online,
  google_login_connect,
  google_login_disconnect,
  get_all_industry_data_details,
  get_all_pricing,
  intent_for_payment,
  get_create_payment_details,
  complete_payment_from_web,
  call_noun_project_api_id,
  get_user_information,
  get_all_customer_booking,
  get_user_templete,
  get_home_page_data,
  get_canvas_editor_data,
  get_canvas_editor_data_user,
  post_customfont_data,
  save_update_admin_templates,
  save_update_user_templates,
  delete_user_templates,
  get_home_data,
  save_update_user_templates_dublicate,
  get_package_by_id,
  get_package_by_id_mk,
  get_admin_template_by_industry,
  get_user_package_information,
  FONT_URL,
  get_package_by_customer_id,
  verifyuser,
  update_logo_to_brandkit,
  get_brandkit_by_user,
  get_customer_all_package,
  //website Links
};
