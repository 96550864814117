import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Components/Css/ViewEnvelops.css";
import madeforyouImg from "../Assets/Images/made_for_you.svg";
import allTemplates from "../Assets/Images/all_templates.svg";
import pen from "../Assets/Images/penIcon.svg";
import createBlank from "../Assets/Images/create_blank.svg";
import Header from "./Header";
import { fabric } from "fabric";
import {
  server_post_data,
  get_user_templete,
} from "../ServiceConnection/serviceconnection.js";
import { handleError, handleLinkClick } from "../CommonJquery/CommonJquery";
import Loader from "./Loader.js";
const ViewEnvelops = () => {
  let template_type = "Envelop";
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [businessCards, setbusinessCards] = useState([]);
  const [businessCards2, setbusinessCards2] = useState([]);

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    fd.append("template_type", template_type);
    await server_post_data(get_user_templete, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data.length > 0) {
            setbusinessCards(Response.data.message.data);
            setbusinessCards2(Response.data.message.data2);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const createCanvas = (ref, logoData) => {
    if (!ref) return;

    const templatejson = logoData.template_canvas;

    if (!templatejson.scaled) {
      const originalWidth = 300;
      const originalHeight = 130;
      const scaleFactor = 130 / Math.max(originalWidth, originalHeight);

      const canvas = new fabric.Canvas(ref, {
        width: 300,
        height: 130,
        backgroundColor: templatejson.background,
      });

      if (templatejson.objects) {
        templatejson.scaled = true;
        templatejson.objects.forEach((objData) => {
          objData.scaleX = (objData.scaleX || 1) * scaleFactor;
          objData.scaleY = (objData.scaleY || 1) * scaleFactor;
          objData.left = (objData.left || 0) * scaleFactor;
          objData.top = (objData.top || 0) * scaleFactor;
          objData.selectable = false; // Disable selection
          objData.evented = false; // Prevent objects from receiving events

          fabric.util.enlivenObjects([objData], (objects) => {
            const origRenderOnAddRemove = canvas.renderOnAddRemove;
            canvas.renderOnAddRemove = false;
            objects.forEach((obj) => {
              canvas.add(obj);
            });
            canvas.renderOnAddRemove = origRenderOnAddRemove;
            canvas.requestRenderAll();
          });
        });

        canvas.selection = false; // Disable group selection
        canvas.renderAll();
        return canvas;
      }
    }
  };

  return (
    <div className="viewBusinesscard_section">
      {showLoaderAdmin && <Loader />}
      <Header Showheader={true} CheckLogin={true} />
      <div className="container">
        <div className="businesscard_wrapper">
          <div className="brandkit_heading_links businessCard_heading_links">
            <span className="flexRow">
              <Link to="/home">Home</Link>
              <h6>/ Envelops</h6>
            </span>
            <div className="businessCard_heading_buttons invitationCard_heading_buttons">
              <div className="brand_kit_button brand_kit_buttonnn invitationCard_brand_kit_buttonnn">
                <img src={pen} alt="penicon" />
                <Link
                  style={{
                    color: "#666666",
                    fontWeight: "500",
                    fontSize: "14px",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  to="/brandkit"
                >
                  Brand Kit
                  <input
                    name="company_logo_new"
                    id="logoInput"
                    style={{ display: "none" }}
                  />
                </Link>
              </div>
              <div className="brand_kit_button brand_kit_buttonnn">
                <label
                  style={{
                    color: "#666666",
                    fontWeight: "500",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  Select Photo
                  <input
                    name="company_logo_new"
                    id="logoInput"
                    style={{ display: "none" }}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="BussinEstxt">
            <p>Envelope</p>
          </div>
          <p className="businessCard_dimensions">595x282 mm</p>
          <div className="made_for_you_text_wrapper">
            <img src={madeforyouImg} alt="made for you Image" />
            <p>Made for you</p>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 col-12">
              {/* <Link to={"/canvasEdit/" + template_type + "/0"} target="_blank">
                <div className="create_blank_div envelops_create_blank_div">
                  <img src={createBlank} alt="create blank" />
                </div>
              </Link> */}
              <div className="create_blank_div envelops_create_blank_div">
                <img src={createBlank} alt="create blank" />
              </div>
            </div>
            {/* {businessCards.map((businessImage, index) => (
              <div className="col-lg-3 col-md-3" key={`Bcard-${index}`}>
                <div
                  className="businessCards_images_div "
                  style={{ width: 300, height: 130 }}
                  // onClick={() =>
                  //   handleLinkClick(
                  //     "/canvasEdit/" +
                  //       template_type +
                  //       "/" +
                  //       businessImage.primary_id
                  //   )
                  // }
                >
                  <img
                    src={`https://api.logoacademy.co/assets/${businessImage.template_url}`}
                    alt="favorite_logos"
                  />
                </div>
              </div>
            ))} */}
          </div>
          <div className="all_templates_pictures_wrapper py-3">
            <div className="made_for_you_text_wrapper">
              <img src={allTemplates} alt="made for you" />
              <p>All Templates</p>
            </div>
          </div>
          <div className="row">
            {!businessCards
              ? []
              : businessCards.map((businessImage, index) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-6 px-sm-1 p-2"
                    key={`Bcard-${index}`}
                  >
                    <div
                      className="businessCards_images_div "
                      // onClick={() =>
                      //   handleLinkClick(
                      //     "/canvasEdit/" +
                      //       template_type +
                      //       "/" +
                      //       businessImage.primary_id
                      //   )
                      // }
                    >
                      <img
                        src={`https://api.logoacademy.co/assets/${businessImage.template_url}`}
                        alt="favorite_logos"
                      />
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEnvelops;
